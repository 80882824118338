import { Divider, Flex, Typography } from "antd";

function ClassmentList({ firstPartTitle, firstPartContent, secondPartTitle, secondPartContent }:
    { firstPartTitle: string, firstPartContent: string[], secondPartTitle: string, secondPartContent: string[] }) {
    return (
        <Flex style={{width: "100%"}} align="center">
            <Flex style={{width: "50%"}} align="center" vertical>
                <Typography.Title level={5} style={{textAlign: "center"}}>{firstPartTitle}</Typography.Title>
                {firstPartContent.map((content: any) => {
                    return <Typography.Text key={content}>{content}</Typography.Text>
                })}
            </Flex>
            <Divider type="vertical" style={{height: "100px"}}/>
            <Flex style={{width: "50%"}} align="center" vertical>
                <Typography.Title level={5} style={{textAlign: "center"}}>{secondPartTitle}</Typography.Title>
                {secondPartContent.map((content: any) => {
                    return <Typography.Text key={content}>{content}</Typography.Text>
                })}
            </Flex>
        </Flex>
    )
}

export default ClassmentList;