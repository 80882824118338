import { Flex, Table, TableColumnsType, Typography, Divider } from "antd";
import OrganizationSelect from "../../../component/organization/OrganizationsSelect";
import WeightDisplay from "../../../component/organization/WeightDisplay";
import { useTranslation } from "react-i18next";
import CreateOrganization from "../../../component/organization/CreateOrganization";
import { useEffect, useState } from "react";
import PetOrganizationDrawer from "../../../component/organization/drawer/PetOrganizationDrawer";

interface TableData {
    key: string;
    name: string;
    owner: string;
    age: number;
    healthStatus: {
        status: string;
    };
    points: {
        score: number;
        status: string;
    };
    weightStatus: {
        weight: number;
        status: string;
        evolution: number;
    }
    activityStatus: {
        status: string;
        time: number;
    };
    vetAppointments: {
        motive: string;
        date: string;
    }[];
    treatments: {
        lateVaccine: number;
        lateDeworming: number;
        lateAntiparasitic: number;
    };
}

function OrganizationTable(props: any) {
    const {t} = useTranslation();
    const [petDrawerOpen, setPetDrawerOpen] = useState(false);
    const [selectedPet, setSelectedPet] = useState<any>();
    const organizations = props.organizations;
    const selectedOrganization = props.organization;
    const organizationInfo = props.organizationInfo;

    const columns: TableColumnsType<TableData> = [
        {
            title: t("organization.animals.name"),
            dataIndex: "name",
            key: "name",
        },
        {
            title: t("organization.animals.owner"),
            dataIndex: "userId",
            key: "userId",
            render: (text) => {
                const owner = organizationInfo.pet_owners.find((owner: any) => owner._id === text);
                return owner ? `${owner.firstname} ${owner.lastname}` : "";
            },
        },
        {
            title: t("organization.animals.age"),
            dataIndex: "age",
            key: "age",
            sorter: (a, b) => a.age - b.age,
            render: (text) => {
                if (text === 0)
                    return <span>{t("organization.animals.less-than-year")}</span>;
                return <span>{text} {t("organization.animals.years")}</span>;
            },
        },
        {
            title: t("organization.animals.health-status"),
            dataIndex: "healthStatus",
            key: "healthStatus",
            render: (healthStatus) => {
                let color = "";
                const status = healthStatus.status;
                if (status === "positive") {
                    color = "green";
                } else if (status === "warning") {
                    color = "orange";
                } else if (status === "danger") {
                    color = "red";
                } else if (status === "advice") {
                    color = "blue";
                }
                return (
                    <span style={{ display: "inline-block", width: 17, height: 17, borderRadius: "50%", backgroundColor: color }}></span>
                );
            },
            filters: [
                { text: <span style={{color: "green"}}>{t("organization.animals.health.positive")}</span>, value: "positive" },
                { text: <span style={{color: "blue"}}>{t("organization.animals.health.advice")}</span>, value: "advice" },
                { text: <span style={{color: "orange"}}>{t("organization.animals.health.warning")}</span>, value: "warning" },
                { text: <span style={{color: "red"}}>{t("organization.animals.health.danger")}</span>, value: "danger" },
            ],
            onFilter: (value, record) => record.healthStatus.status.indexOf(value as string) === 0,
            sorter: (a, b) => {
                const order = ["danger", "warning", "advice", "positive"];
                return order.indexOf(a.healthStatus.status) - order.indexOf(b.healthStatus.status);
            },
        },
        {
            title: t("organization.animals.points"),
            dataIndex: "points",
            key: "points",
            render: (points) => {
                return (
                    <Typography.Text>{points.score}</Typography.Text>
                );
            },
            sorter: (a, b) => a.points.score - b.points.score,
        },
        {
            title: t("organization.animals.weight"),
            dataIndex: "weightStatus",
            key: "weightStatus",
            render: (status) => (
                <WeightDisplay
                    weight={status.weight}
                    weightStatus={status.status}
                    weightDifference={status.evolution}
                />
            ),
            filters: [
                { text: t("organization.animals.weight-status.underweight"), value: "underweight" },
                { text: t("organization.animals.weight-status.normal"), value: "normal" },
                { text: t("organization.animals.weight-status.overweight"), value: "overweight" },
            ],
            onFilter: (value, record) => record.weightStatus.status.indexOf(value as string) === 0,
        },
        {
            title: t("organization.animals.activity"),
            dataIndex: "activityStatus",
            key: "activityStatus",
            filters: [
                { text: t("organization.animals.activity-status.low"), value: "low" },
                { text: t("organization.animals.activity-status.normal"), value: "normal" },
                { text: t("organization.animals.activity-status.high"), value: "high" },
            ],
            onFilter: (value, record) => record.activityStatus.status.indexOf(value as string) === 0,
            render: (activityStatus) => {
                const level = activityStatus.status;
                if (level === "low")
                    return <span>{t("organization.animals.activity-status.low")}</span>;
                else if (level === "normal")
                    return <span>{t("organization.animals.activity-status.normal")}</span>;
                return <span>{t("organization.animals.activity-status.high")}</span>;
            },
            sorter: (a, b) => {
                const order = ["low", "normal", "high"];
                return order.indexOf(a.activityStatus.status) - order.indexOf(b.activityStatus.status);
            },
        },
        {
            title: t("organization.animals.vaccine"),
            dataIndex: "treatments",
            key: "treatments",
            render: (treatment) => {
                if (treatment.lateVaccine === 0)
                    return <span>{t("organization.animals.vaccine-status.up-to-date")}</span>;
                return <span>{t("organization.animals.vaccine-status.late")}</span>
            },
            filters: [
                { text: t("organization.animals.vaccine-status.up-to-date"), value: "up-to-date" },
                { text: t("organization.animals.vaccine-status.late"), value: "late" },
            ],
            onFilter: (value, record) => {
                if (value === "up-to-date") {
                    return record.treatments.lateVaccine === 0;
                }
                return record.treatments.lateVaccine > 0;
            },
        },
        {
            title: t("organization.animals.vet"),
            dataIndex: "vetAppointments",
            key: "vetAppointments",
            sorter: (a, b) => a.vetAppointments.length - b.vetAppointments.length,
            render: (visits) => {
                if (visits.length === 0)
                    return <span>{t("organization.animals.vet-status.no-appointment")}</span>;
                return <span>{visits.length} {t("organization.animals.vet-status.appointment")}</span>;
            }
        }
    ];

    const data = organizationInfo.animals;

    if (selectedOrganization === undefined) {
        return (
            <>
                <OrganizationSelect organization={selectedOrganization} organizations={organizations} onOrganizationChange={props.onOrganizationChange}/>
                <Flex vertical align="center" justify="center" style={{height: "100%"}}>
                    <Typography.Title level={3}>{t("organization.no-organization")}</Typography.Title>
                    <Typography.Text>{t("organization.no-organization-description")}</Typography.Text>
                    <Divider>{t("linkers.or")}</Divider>
                    <CreateOrganization renewOrganizations={props.renewOrganizations}/>
                </Flex>
            </>
        )
    }

    return (
        <Flex vertical gap={"large"}>
            <OrganizationSelect organization={props.organization} organizations={props.organizations} onOrganizationChange={props.onOrganizationChange}/>
            <Table
                loading={props.loading}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            setPetDrawerOpen(true);
                            setSelectedPet(record);
                        },
                    };
                }}
                columns={columns}
                dataSource={data}
            />
            <PetOrganizationDrawer organizationId={organizationInfo._id} open={petDrawerOpen} onClose={() => setPetDrawerOpen(false)} selectedPet={selectedPet}/>
        </Flex>
    )
}

export default OrganizationTable;