import { Divider, Flex, Typography } from "antd";
import OrganizationSelect from "../../../component/organization/OrganizationsSelect";
import OrganizationCard from "../../../component/organization/OrganizationCard";
import CreateOrganization from "../../../component/organization/CreateOrganization";
import { useTranslation } from "react-i18next";

function OrganizationManagement(props: any) {
    const {t} = useTranslation();
    const organizations = props.organizations;
    const selectedOrganization = props.organization;

    if (selectedOrganization === undefined) {
        return (
            <>
                <OrganizationSelect organization={selectedOrganization} organizations={organizations} onOrganizationChange={props.onOrganizationChange}/>
                <Flex vertical align="center" justify="center" style={{height: "100%"}}>
                    <Typography.Title level={3}>{t("organization.no-organization")}</Typography.Title>
                    <Typography.Text>{t("organization.no-organization-description")}</Typography.Text>
                    <Divider>{t("linkers.or")}</Divider>
                    <CreateOrganization renewOrganizations={props.renewOrganizations}/>
                </Flex>
            </>
        )
    }

    return (
        <Flex vertical gap={"large"}>
            <OrganizationSelect
                organization={props.organization}
                organizations={props.organizations}
                onOrganizationChange={props.onOrganizationChange}
            />
            <OrganizationCard
                organization={props.organizationInfo}
                renewOrganizations={props.renewOrganizations}
                onOrganizationChange={props.onOrganizationChange}
                renewOrganizationInfo={props.renewOrganizationInfo}
                loading={props.loading}
            />
            <Divider>{t("linkers.or")}</Divider>
            <CreateOrganization renewOrganizations={props.renewOrganizations}/>
        </Flex>
    )
}

export default OrganizationManagement;