import { Typography, Tooltip, Badge, Flex } from "antd";
import BehaviorData from "../../behavior/BehaviorData";
import { customTheme } from "../../../config/theme";

const behaviorStyle = {
    backgroundColor: customTheme.colorPrimary,
    borderRadius: "25px",
    padding: "10px 15px",
    color: "white",
    fontWeight: "bold",
}

function OrganizationBehaviorsDisplay({behaviors}: {behaviors: number[]}) {
    const behaviorsData = BehaviorData();

    function getBehaviorLabel(behaviorId: number, behaviorsData: any) {
        for (const behavior of behaviorsData) {
            if (behavior.details) {
                for (const detail of behavior.details) {
                    if (detail.id === behaviorId) {
                        return detail.key;
                    }
                }
            }
            if (behavior.id === behaviorId) {
                return behavior.label;
            }
        }
    }

    function reverseDate(date: string) {
        return date.split("-").reverse().join("/");
    }

    return (
        <Flex gap={"small"} style={{marginTop: "10px"}}>
            {behaviors.map((behavior: any) => {
                return (
                    <Tooltip title={reverseDate(behavior.createdAt)} placement="top" arrow>
                        <Badge count={behavior.count === 1 ? 0 : behavior.count} color="grey">
                            <Typography.Text key={behavior.value} style={behaviorStyle}>{getBehaviorLabel(behavior.value, behaviorsData)}</Typography.Text>
                        </Badge>
                    </Tooltip>
                )
            })}
        </Flex>
    );
}

export default OrganizationBehaviorsDisplay;