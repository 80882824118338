import { Flex } from "antd";
import { GoArrowDownRight, GoArrowUpRight } from "react-icons/go";
import { PiEquals } from "react-icons/pi";

function TrendArrow({weight, weightDifference}: {weight: number; weightDifference: number;}) {
    let color = 'black';

    if (weightDifference > 0) {
        return <GoArrowUpRight style={{ fontSize: '18px', color: color}}/>
    } else if (weightDifference < 0) {
        return <GoArrowDownRight style={{ fontSize: '18px', color: color }}/>
    }
    return <PiEquals style={{ fontSize: '18px', color: color }}/>
}

function WeightDisplay({ weight, weightStatus, weightDifference }: { weight: number; weightStatus: string; weightDifference: number; }) {
    return (
        <Flex gap={5}>
            <span style={{color: (weightStatus == "normal" ? "green" : "#CF1020")}}>{weight}</span>
            <span>kg</span>
            <Flex align="center">
                (<TrendArrow weight={weight} weightDifference={weightDifference}/>)
            </Flex>
        </Flex>
    );
}

export default WeightDisplay;