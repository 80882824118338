import { Gauge } from "@mui/x-charts";

function VaccineChart(props: any) {
    return (
        <Gauge
            value={props.value}
            valueMax={props.valueMax}
            width={200}
            height={200}
            text={
                ({ value, valueMax }) => `${value} / ${valueMax}`
            }
        />
    )
}

export default VaccineChart;