import axios from 'axios';

/**
 * Fetches specific user details.
 * @param accessToken - The access token for authorization.
 * @returns The user details or null if an error occurs.
 */
const getSpecificUser = async (accessToken: string) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/user`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                withCredentials: true
            },
        );
        return response.data;
    } catch (error: any) {
        return null;
    }
}

/**
 * Fetches the user's score.
 * @param accessToken - The access token for authorization.
 * @returns The user score or null if an error occurs.
 */
const getUserScore = async (accessToken: string) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/user/score`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                withCredentials: true
            },
        );
        return response.data;
    } catch (error: any) {
        return null;
    }
}

/**
 * Updates user details.
 * @param accessToken - The access token for authorization.
 * @param firstname - The first name of the user.
 * @param lastname - The last name of the user.
 * @param phoneNumber - The phone number of the user.
 * @returns The response from the server or an error.
 */
const updateUser = async (accessToken: string, firstname: string, lastname: string, phoneNumber: string) => {
    try {
        const data = JSON.stringify({firstname, lastname, phoneNumber}, null, 1)
        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/user`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                withCredentials: true
            },
        );
        return response;
    } catch (error: any) {
        return error;
    }
}

/**
 * Updates user email.
 * @param accessToken - The access token for authorization.
 * @param email - The new email of the user.
 * @param user_id - The ID of the user.
 * @returns The response from the server or an error.
 */
const updateUserEmail = async (accessToken: string, email: string, user_id: string) => {
    try {
        const authToken = await axios.post(`https://${process.env.REACT_APP_AUTH_DOMAIN}/oauth/token`,
            {
                grant_type: 'client_credentials',
                client_id: process.env.REACT_APP_AUTH_CLIENT_ID as string,
                client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET as string,
                audience: `https://${process.env.REACT_APP_AUTH_DOMAIN}/api/v2/`,
            },
            {
                headers: {
                    'content-type': 'application/json'
                },
            }
        )
        const authResponse = await axios.patch(`https://${process.env.REACT_APP_AUTH_DOMAIN}/api/v2/users/${user_id}`,
            JSON.stringify({email, name: email}, null, 1),
            {
                headers: {
                    'content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken.data.access_token}`
                },
            }
        )
        if (authResponse.status == 200) {
            const data = JSON.stringify({email}, null, 1)
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/user/email`,
                data,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    withCredentials: true
                },
            );
            return response;
        } else {
            return {}
        }
    } catch (error: any) {
        return error;
    }
}

/**
 * Updates user password.
 * @param accessToken - The access token for authorization.
 * @param password - The new password.
 * @param old_password - The old password.
 * @returns The response from the server or an error.
 */
const updateUserPassword = async (accessToken: string, password: string, old_password: string) => {
    try {
        const data = {
            password: password,
            oldPassword: old_password
        }
        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/user/password`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                withCredentials: true
            },
        );
        return response;
    } catch (error: any) {
        return error;
    }
}


export {
    getSpecificUser,
    getUserScore,
    updateUser,
    updateUserEmail,
    updateUserPassword
}
