import { ConfigProvider, Form, Layout, Tabs } from 'antd';
import React, { useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import './profile.css'
import VetsDisplay from './VetsDisplay';
import { customTheme } from '../../config/theme';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '../../state/globalState';
import FamilyDisplay from '../family/FamilyDisplay';
import ProfileTab from './ProfileTab';

export interface Invitation {
    _id: string
    family: {
        name: string
    },
    sender: {
        firstname: string
        lastname: string
        email: string
    }
}

function ProfileDisplay(props: any) {
    const {t} = useTranslation();
    const user = props.user;
    const [invitations, setInvitations] = useGlobalState('invitations');

    return (
        <ConfigProvider
            theme={{
                components: {
                    Tabs: {
                        itemSelectedColor: customTheme.colorPrimary,
                        inkBarColor: customTheme.colorPrimary,
                        itemHoverColor: 'rgba(0, 123, 255, 0.8)',
                        itemColor: 'black'
                    },
                }
            }}
        >
            <Layout>
                <Layout.Content style={{ backgroundColor: '#E6F7FF' }}>
                        <Tabs
                            centered
                            className='profile-tabs'
                            defaultActiveKey="1"
                            items={[
                                {
                                    key: '1',
                                    label: t("my-profile.title"),
                                    children:
                                        <ProfileTab user={user}/>
                                },
                                {
                                    key: '2',
                                    label: t("vet-profile.title"),
                                    children:
                                        <VetsDisplay/>
                                },
                                {
                                    key: '3',
                                    label: t("family.title"),
                                    icon: invitations.length >= 1 && <ExclamationCircleOutlined style={{ color: 'red', marginRight: '-5px' }}/>,
                                    children:
                                        <FamilyDisplay user={user} invitations={invitations}/>
                                }
                            ]}
                        />
                </Layout.Content>
            </Layout>
        </ConfigProvider>
    );
}

export default ProfileDisplay;
