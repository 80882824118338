import { Flex, Layout, Menu, message } from "antd";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import { useEffect, useState } from "react";
import OrganizationSummary from "./subPage/OrganizationSummary";
import OrganizationTable from "./subPage/OrganizationTable";
import { useTranslation } from "react-i18next";
import OrganizationManagement from "./subPage/OrganizationManagement";
import { getOrganizationInfo, getOrganizations } from "../../request/organization";
import { useAuth0 } from "@auth0/auth0-react";
import { HomeOutlined } from "@ant-design/icons";
import ChangeLang from "../../component/lang/ChangeLang";
import LeaveConfirm from "../../component/confirm/LeaveConfirm";

interface OrganizationInterface {
  _id: string;
  name: string;
  members: any[];
  petOwners: any[];
}

function Organization() {
  const [selectedKey, setSelectedKey] = useState<"1" | "2" | "3">("1");
  const {t} = useTranslation();
  const [organizations, setOrganizations] = useState([]);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const [selectedOrganization, setSelectedOrganization] = useState<OrganizationInterface>(organizations[0] || undefined);
  const [organizationInfo, setOrganizationInfo] = useState({});
  const [loading, setLoading] = useState(false);

  const contentMap: { [key in "1" | "2" | "3"]: JSX.Element } = {
    "1": <OrganizationSummary
      organization={selectedOrganization}
      organizations={organizations}
      onOrganizationChange={(e: any) => setSelectedOrganization(e)}
      organizationInfo={organizationInfo}
      loading={loading}
    />,
    "2": <OrganizationTable
      organization={selectedOrganization}
      organizations={organizations}
      onOrganizationChange={(e: any) => setSelectedOrganization(e)}
      organizationInfo={organizationInfo}
      loading={loading}
      />,
    "3": <OrganizationManagement
      organization={selectedOrganization}
      organizations={organizations}
      onOrganizationChange={(e: any) => setSelectedOrganization(e)}
      renewOrganizations={getUserOrganizations}
      organizationInfo={organizationInfo}
      renewOrganizationInfo={getSelectedOrganizationInfo}
      loading={loading}
      />,
  };

  async function getAccessToken() {
    if (isAuthenticated) {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
          scope: "openid profile email",
        },
      });
      return accessToken;
    }
    return "";
  }

  async function getUserOrganizations() {
    try {
      const accessToken = await getAccessToken();
      const organizations = await getOrganizations(accessToken);
      if (organizations) {
        setOrganizations(organizations);
      }
    } catch(err) {
      console.log(err);
      message.error("Failed to get organizations");
    }
  }

  async function getSelectedOrganizationInfo() {
    setLoading(true);
    try {
      const accessToken = await getAccessToken();
      const organizationInformations = await getOrganizationInfo(accessToken, selectedOrganization._id);
      if (organizationInformations) {
        setOrganizationInfo(organizationInformations);
      }
    } catch(err) {
      message.error("Failed to get organization info");
    }
    setLoading(false);
  }

  useEffect(() => {
    getUserOrganizations();
  }, []);

  useEffect(() => {
    if (selectedOrganization != undefined) {
      getSelectedOrganizationInfo();
    }
  }, [selectedOrganization]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["1"]}
          onClick={(e) => setSelectedKey(e.key as "1" | "2" | "3")}
          items={[
            { key: "1", label: t("organization.tabs.dashboard") },
            { key: "2", label: t("organization.tabs.animals") },
            { key: "3", label: t("organization.tabs.management") },
          ]}
        />
        <Flex style={{position: "absolute", bottom: "20px", width: "100%"}} gap={"small"} vertical align="center" justify="center">
          <ChangeLang vertical dropDownMode/>
          <LeaveConfirm
            path="/application"
            textPath="organization.leave-confirm"
          >
            <HomeOutlined
              style={{ color: "white", fontSize: "25px", cursor: "pointer" }}
            />
          </LeaveConfirm>
        </Flex>
      </Sider>
      <Content style={{ padding: "20px", backgroundColor: "var(--colorBackground)" }}>
        {contentMap[selectedKey] || <div>Contenu non trouvé</div>}
      </Content>
    </Layout>
  );
}

export default Organization;
