import React from 'react';
import { Flex, type MenuProps } from 'antd';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { ReactComponent as AccueilIcon } from "../../assets/icons/Accueil.svg";
import { ReactComponent as HealthRecordIcon } from "../../assets/icons/CarnetSante.svg";
import { ReactComponent as PetProfileIcon } from "../../assets/icons/ProfilAnimal.svg";
import { ReactComponent as UserProfileIcon } from "../../assets/icons/ProfilUtilisateur.svg";
import { useTranslation } from 'react-i18next';

export const SideBarData = () => {
    const {t} = useTranslation();

    return [
        {
            key: '/application',
            label: t("sidebar.home"),
            icon: (color: string, scale: number) =>
                <Flex justify='center' style={{ alignItems: 'center', height: '20px', width: '20px', transform: `scale(${scale})` }}>
                    <AccueilIcon style={{ color: color }}/>
                </Flex>
        },
        {
            key: '/application/calendar',
            label: t("sidebar.calendar"),
            icon: (color: string, scale: number, margin?: boolean) =>
                <Flex justify='center' style={{ alignItems: 'center', height: '18px', width: '18px', marginRight: margin ? '2px' : '0', transform: `scale(${scale})` }}>
                    <CalendarMonthIcon style={{ color: color, height: '100%', width: '100%' }}/>
                </Flex>
        },
        {
            key: '/application/health',
            label: t("sidebar.health"),
            icon: (color: string, scale: number) =>
                <Flex justify='center' style={{ alignItems: 'center', height: '20px', width: '20px', transform: `scale(${scale})` }}>
                    <HealthRecordIcon style={{ color: color }}/>
                </Flex>
        },
        {
            key: '/application/pets',
            label: t("sidebar.pets"),
            icon: (color: string, scale: number) =>
                <Flex justify='center' style={{ alignItems: 'center', height: '20px', width: '20px', transform: `scale(${scale})` }}>
                    <PetProfileIcon style={{ color: color }}/>
                </Flex>
        },
        {
            key: '/application/profile',
            label: t("sidebar.profile"),
            icon: (color: string, scale: number) =>
                <Flex justify='center' style={{ alignItems: 'center', height: '20px', width: '20px', transform: `scale(${scale})` }}>
                    <UserProfileIcon style={{ color: color }}/>
                </Flex>
        },
    ]
}