import { FlagOutlined } from "@ant-design/icons";
import { Button, Flex, Radio, Select, Tooltip, Typography } from "antd";
import { changeLanguage } from "i18next";

import { useEffect, useState } from "react";
import './ChangeLang.css'

interface ChangeLangage {
    dropDownMode?: boolean,
    vertical?: boolean
}

function ChangeLang({dropDownMode, vertical}: ChangeLangage) {
    const [selectedLangage, setSelectedLangage] = useState("fr");

    function changeLanguageAndLocalStorage(lang: string) {
        changeLanguage(lang);
        localStorage.setItem('savedLanguage', lang);
        document.documentElement.lang = lang;
        setSelectedLangage(lang)
    }

    const langage = [
        {label: "Français", value: "fr", tooltip: "Passer au français"},
        {label: "English", value: "en", tooltip: "Switch to English"},
    ]

    useEffect(() => {
        const lang = localStorage.getItem('savedLanguage');

        if (lang) {
            setSelectedLangage(lang)
        } else if (!lang) {
            changeLanguageAndLocalStorage(selectedLangage);
        }
    }, [])

    return (
        <>
            {dropDownMode
                ?   <Flex align="center" justify="center" gap={"large"}>
                        {langage.map((value, key: number) => (
                            <Flex onClick={(target) => changeLanguageAndLocalStorage(value.value)} className="lang-button">
                                <img
                                    src={`assets/images/flags/${value.value}.png`}
                                    height={"100%"}
                                    alt={`Change lang to ${value.label}`}
                                />
                            </Flex>
                        ))}
                    </Flex>
                :   <Flex align="center" justify="center" gap={"small"} vertical={vertical}>
                        {langage.map((value, key: number) => (
                            <Tooltip title={value.tooltip} key={key}>
                                <Button
                                    type="primary"
                                    shape="round"
                                    icon={<FlagOutlined />}
                                    onClick={() => changeLanguageAndLocalStorage(value.value)}
                                    style={{ display: 'flex', alignItems: 'center' }}
                                >
                                    {value.label}
                                </Button>
                            </Tooltip>
                        ))}
                    </Flex>
            }
        </>
    );
}

export default ChangeLang;