import { Flex, Typography } from "antd";
import { customTheme } from "../../../config/theme";
import { IoPaw } from "react-icons/io5";

function SimpleScoreDisplay({score}: any) {
    return (
        <Flex
            justify="end"
            align="center"
            style={{
                height: '70px', backgroundColor: 'rgba(197, 194, 194, 0)',
            }}
        >
            <Flex style={{ border: 'solid calc(1px * 1.3) #084687', borderRadius: 'calc(7px + 1px * 0.5)'}}>
                <Flex
                    style={{
                        minWidth: '100px', width: 'auto', height: '40px',
                        backgroundColor: customTheme.colorPrimary,
                        border: `solid 2px #0c65c4`, borderRadius: '6px',
                        boxShadow: "2px 3px 0 #084687"
                    }}
                    className="point-container"
                    justify="center"
                >
                    <Flex align="center" gap={8} style={{ marginLeft: '5px', marginRight: '5px' }}>
                        <Flex>
                            <IoPaw style={{ color: 'white', fontSize: '20px', transform: 'rotate(-30deg)'}}/>
                        </Flex>
                        <Typography.Text className="score"  style={{ color: 'white', fontSize: '22px', fontWeight: 'bold' }}>{score}</Typography.Text>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default SimpleScoreDisplay;