import React, { useEffect, useState } from "react"
import { Button, Divider, Drawer, Flex, Form, Input, InputNumber, Select, Typography } from "antd";
import dayjs from 'dayjs';
import _ from 'lodash';
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

import './calendarDrawer.css'
import { ExclamationCircleOutlined, InfoCircleOutlined, LeftOutlined } from "@ant-design/icons";
import { updateEvent, deleteEvent, updateMedicines, removeMedicinesFromArray } from "../../../request/events";
import SelectPet from "../../navBar/SelectPet";
import DeleteConfirm from "../../confirm/DeleteConfirm";
import { dateFormat } from "../../../config/format";
import TreatmentValidationModal from "../modal/TreatmentValidationModal";
import CustomDatePicker from "../../customPicker/CustomDatePicker";
import LinkModal from "../modal/LinkModal";
import { getTranslatedEventType } from "../CalendarDisplay";
import { LinkedAppointmentDisplay } from "../LinkDisplay";

function formatDate(dateToFormat: string) {
    if (typeof(dateToFormat) == 'string') {
        const [day, month, year] = dateToFormat.split('-');
        const formattedDateTime = `${year}-${month}-${day}`;

        return dayjs(formattedDateTime)
    }
    return dateToFormat;
}

function formatDateEvent(dateToFormat: string) {
    const [date, time] = dateToFormat.split(' ');
    const [day, month, year] = date.split('-');

    return `${year}-${month}-${day}`;
}

function getRenewSentence(event: any, t: TFunction<"translation", undefined>) {
    const today = new Date().setHours(0, 0, 0, 0);
    const eventDate = new Date(formatDateEvent(event.date)).getTime();
    const diffInDays = Math.floor((eventDate - today) / (1000 * 60 * 60 * 24));

    if (diffInDays < 0) {
        return t("treatment-reminder-drawer.renew-past", { diffInDays: Math.abs(diffInDays) });
    } else if (diffInDays === 0) {
        return t("treatment-reminder-drawer.renew-today");
    } else {
        return t("treatment-reminder-drawer.renew-comming", { diffInDays: diffInDays });
    }
}

function EventDrawerTreatment(props: any) {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const {t} = useTranslation();

    const [open, setOpen] = useState(true);

    const [modify, setModify] = useState(false);
    const event = props.event;

    const [pet, setPet] = useState<{pet_id: string, species: string, name: string}>(event.pet);

    const [date, setDate] = useState(formatDate(event.date));
    const [frequency, setFrequency] = useState(event.frequency || {number: 1, unit: 'day'});
    const [treatment, setTreatment] = useState(event.treatment);
    const [notes, setNotes] = useState(event.notes);

    const [modalVisible, setModalVisible] = useState(false);
    const [affiliationModalVisible, setAffiliationModalVisible] = useState(false);

    const getAccessToken = async () => {
        if (isAuthenticated) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
              scope: "openid profile email",
            },
          });
          return accessToken;
        }
        return "";
    }

    const changeEvent = () => {
        const formattedDate = dayjs(date).format(`${dateFormat}`);

        event.pet = pet;
        event.treatment = treatment;
        event.notes = notes;
        event.date = `${formattedDate}`;
    }

    const handleSubmit = async () => {
        try {
            const newDate = dayjs(date).format(`${dateFormat}`);

            if (event.name !== "medicines") {
                await updateEvent(await getAccessToken(), { pet_id: pet.pet_id }, newDate, {vet_id: ""}, treatment, "", notes, event._id)
            } else {
                let renew_date = false
                if (date.format(dateFormat) != event.date || frequency != event.frequency) {
                    renew_date = true;
                }
                await updateMedicines(await getAccessToken(),
                    {
                        id: event._id,
                        index: event.index,
                        pet: { pet_id: pet.pet_id },
                        date: newDate,
                        medicines_date: event.document_date,
                        frequency: frequency,
                        renew_date: renew_date,
                        treatment: treatment,
                        notes: notes
                    }
                )
            }
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
        setModify(false);
        if (props.renew) {
            props.renew();
        }
        changeEvent();
    }

    const handleSelectPet = (pet: {pet_id: string, species: string, name: string}) => {
        setPet({pet_id: pet.pet_id, species: pet.species, name: pet.name});
    }

    const removeEvent = async () => {
        try {
            if (event.name !== "medicines") {
                await deleteEvent(await getAccessToken(), event._id)
            } else {
                await removeMedicinesFromArray(await getAccessToken(), {id: event._id, index: event.index, date: event.document_date})
            }
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
        if (props.renew) {
            props.renew();
        }
        handleClose(true);
    }

    const handleModify = () => {
        if (modify) {
            setPet(event.pet);
            setDate(formatDate(event.date));
            setTreatment(event.treatment);
            setNotes(event.notes)
        }
        setModify(!modify);
    }

    const handleClose = (reRender: boolean) => {
        setOpen(false)
        props.onClose(reRender);
    }

    useEffect(() => {
        setOpen(true);
    }, []);

    return (
        <>
            <Drawer
                title={`${(event.date).split(' ')[0]} - ${getTranslatedEventType(event.name, t)}`}
                extra={
                    <Button onClick={() => handleModify()}>
                        {!modify ? t("pet-profile.modify") : t("form.cancel")}
                    </Button>
                }
                footer={<DeleteConfirm deleteText={t('confirm.delete.element-remove')} delete={removeEvent}>{t("form.remove")}</DeleteConfirm>}
                onClose={() => handleClose(false)}
                open={open}
                width={650}
                closeIcon={<LeftOutlined />}
            >
                <Flex style={{ flexDirection: 'column', marginBottom: '10px' }}>
                    <Typography.Text style={{ fontWeight: 'bold' }}>{t("appointment-creation.pet")} : </Typography.Text>
                    <SelectPet
                        pet={pet.name}
                        changeCookies={false}
                        onChange={handleSelectPet}
                        disabled={!modify}
                        width='100%'
                    />
                </Flex>
                <Flex style={{ flexDirection: 'column', marginBottom: '10px' }}>
                    <Typography.Text style={{ fontWeight: 'bold' }}>Date :</Typography.Text>
                    <CustomDatePicker
                        value={date}
                        style={{ backgroundColor: 'white', color: 'black', cursor: 'default' }}
                        onChange={(e: any) => setDate(e as any)}
                        format={`${dateFormat}`}
                        disabled={!modify}
                    />
                </Flex>
                { event.name === "medicines" ? (
                    <Flex style={{ flexDirection: 'column', marginBottom: '10px' }}>
                        <Typography.Text style={{ fontWeight: 'bold' }}>{t("treatment-reminder-creation.interval")} :</Typography.Text>
                        <Flex style={{ flexDirection: 'row' }} gap={'4%'}>
                            <Flex
                                style={{ width: '90%' }}
                            >
                                <InputNumber
                                    style={{ width: '100%' }}
                                    className="calendar-number-input"
                                    defaultValue={frequency.number} min={1}
                                    onChange={(e: number | any) => setFrequency({number: e, unit: frequency.unit})}
                                    addonAfter={t("treatment-reminder-creation.every")}
                                    disabled={!modify}
                                />
                            </Flex>
                            <Flex
                                style={{ width: '40%' }}
                            >
                                <Select style={{ width: '100%' }} defaultValue={frequency.unit} onSelect={(e) => setFrequency({number: frequency.number, unit: e})} disabled={!modify}>
                                    <Select.Option value="day">{t("time-units.day")}</Select.Option>
                                    <Select.Option value="week">{t("time-units.week")}</Select.Option>
                                    <Select.Option value="month">{t("time-units.month")}</Select.Option>
                                    <Select.Option value="year">{t("time-units.year")}</Select.Option>
                                </Select>
                            </Flex>
                        </Flex>
                    </Flex>
                ) : (
                    null
                )}
                <Flex style={{ flexDirection: 'column', marginBottom: '10px' }}>
                    <Typography.Text style={{ fontWeight: 'bold' }}>{t("treatment-reminder-drawer.end-date")} :</Typography.Text>
                    <Input
                        value={event.document_date}
                        style={{ backgroundColor: 'white', color: 'black', cursor: 'default' }}
                        disabled={true}
                    />
                </Flex>
                <Flex style={{ flexDirection: 'column', marginBottom: '10px' }}>
                    <Typography.Text style={{ fontWeight: 'bold' }}>{t("treatment-reminder-drawer.treatment")} :</Typography.Text>
                    <Input.TextArea
                        rows={4}
                        value={treatment}
                        style={{ backgroundColor: 'white', color: 'black', cursor: 'default' }}
                        autoSize={{ minRows: 2 }}
                        disabled={!modify}
                        onChange={(e) => setTreatment(e.target.value)}
                    />
                </Flex>
                <Flex style={{ flexDirection: 'column', marginBottom: '10px' }}>
                    <Typography.Text style={{ fontWeight: 'bold' }}>{t("appointment-creation.additional-information")} :</Typography.Text>
                    <Input.TextArea
                        rows={4}
                        value={notes}
                        style={{ backgroundColor: 'white', color: 'black', cursor: 'default' }}
                        autoSize={{ minRows: 3 }}
                        disabled={!modify}
                        onChange={(e) => setNotes(e.target.value)}
                    />
                </Flex>
                {modify &&
                        <Button
                            type="primary"
                            style={{ marginTop: '10px' }}
                            onClick={handleSubmit}
                        >
                            {t("form.save")}
                        </Button>
                }
                {(event.to_renew || event.to_link) && <Divider style={{ margin: 15 }}/>}
                <Flex style={{ flexDirection: 'column' }} gap={20}>
                    {event.to_renew &&
                        <Flex style={{ flexDirection: 'column' }} gap={10}>
                            {event.important &&
                                <Flex style={{ flexDirection: 'row', alignItems: 'center'}} gap={10}>
                                    <ExclamationCircleOutlined
                                        style={{
                                            color: 'red',
                                            fontSize: '20px',
                                        }}
                                    />
                                    <Typography.Text style={{ fontWeight: 'bold' }}>{getRenewSentence(event, t)}</Typography.Text>
                                </Flex>
                            }
                            <Flex>
                                    <Button
                                        type="primary"
                                        onClick={() => setModalVisible(true)}
                                    >
                                        {t("treatment-reminder-drawer.validate")}
                                    </Button>
                                    <TreatmentValidationModal
                                        open={modalVisible}
                                        onFinish={(success: boolean) => {
                                            setModalVisible(false)
                                            handleClose(success);
                                        }}
                                        event={event}
                                        />
                            </Flex>
                        </Flex>
                    }
                    {event.to_link &&
                        <Flex style={{ flexDirection: 'column' }} gap={10}>
                            <Flex style={{ flexDirection: 'row', alignItems: 'center'}}>
                                <InfoCircleOutlined
                                    style={{
                                        color: 'blue',
                                        fontSize: '20px',
                                        marginRight: '10px',
                                    }}
                                />
                                <Typography.Text style={{ fontWeight: 'bold' }}>{t("treatment-reminder-drawer.link-sentence")}</Typography.Text>
                            </Flex>
                            <Flex>
                                <Button
                                        type="primary"
                                        onClick={() => setAffiliationModalVisible(true)}
                                    >
                                        {t("treatment-reminder-drawer.link")}
                                </Button>
                                <LinkModal
                                    open={affiliationModalVisible}
                                    onClose={() => setAffiliationModalVisible(false)}
                                    renew={props.renew}
                                    event={event}
                                    events={props.events}
                                    onFinish={(success: boolean, appointmentId: string) => {
                                        setAffiliationModalVisible(false)
                                        if (success) {
                                            props.renew()
                                            event.to_link = false;
                                            event.linked_event = {event_id: appointmentId}
                                        }
                                    }}
                                />
                            </Flex>
                        </Flex>
                    }
                </Flex>
                {event.linked_event &&
                    <Flex style={{ flexDirection: 'column' }}>
                        <Divider style={{ margin: 15 }}/>
                        <Flex style={{ flexDirection: 'column' }} gap={10}>
                            <Typography.Text style={{ fontWeight: 'bold' }}>{t('treatment-reminder-drawer.link-to')} :</Typography.Text>
                            <LinkedAppointmentDisplay
                                events={props.events}
                                event={props.event}
                                onFinish={() => {
                                    delete event.linked_event
                                    event.to_link = true
                                    props.renew();
                                }}
                                openEvent={props.openEvent}
                            />
                        </Flex>
                    </Flex>
                }
            </Drawer>
        </>
    );
}

export { EventDrawerTreatment, formatDate }