import React, { useEffect, useState } from 'react';
import {Routes, Route, useNavigate, useLocation, Router} from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import './App.css';
import Application from './pages/application/application';
import Pets from './pages//pets/pets'
import Profile from './pages/profile/profile'
import Calendar from './pages/calendar/calendar'
import HealthRecord from './pages/healthRecord/healthRecord';
import Home from './pages/home/home';

import { useGlobalState } from './state/globalState'
import i18n from './lang/i18n';
import { getPetsFromUser } from './request/pets';
import { assignPetAvatar, savePetAvatars } from './component/pets/PetAvatar';
import Organization from './pages/organization/Organization';
import ApplicationRoute from './routes/ApplicationRoute';

function App() {
  const { loginWithRedirect, getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();

  const navigate = useNavigate();
  const location = useLocation();

  const [userPets, setUserPets] = useGlobalState('userPets');
  const [numberOfPets, setNumberOfPets] = useGlobalState('numberOfPets');
  const [isLogin, setIsLogin] = useGlobalState('isLogin');
  const [displayLoading, setDisplayLoading] = useGlobalState('isLoading');
  const [footerVisibility, setFooterVisibility] = useGlobalState('footerVisibility');

  const [previousLocation, setPreviousLocation] = useState(location.pathname);

  const pathToExclude: string[] = ["/login", "/register", "/"];

  const getAccessToken = async () => {
    if (isAuthenticated) {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
          scope: "openid profile email",
        },
      });
      return accessToken;
    }
    return "";
  }

  const getNumberOfPets = async () => {
    const result = await getPetsFromUser(await getAccessToken());
    if (result && result.pets && result.avatars) {
      await savePetAvatars(result.avatars, false);
      await assignPetAvatar(result.pets, "_id", "avatar", true);
      setUserPets(result.pets)
      setNumberOfPets(result.pets.length);
      setDisplayLoading(false)
    }
  }

  async function verifyAccess() {
    try {
      await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
          scope: "read:current_user",
        },
      });
    } catch (err: any) {
      if (err.message == "Login required") {
        loginWithRedirect({
          authorizationParams: {
            scope: "openid profile email",
            ui_locales: localStorage.getItem("savedLanguage") || "en",
            redirect_uri: window.location.href
          },
        });
      }
    }
  }

  const checkLogin = async () => {
    try {
      if (isAuthenticated) {
        verifyAccess()
      }
      setIsLogin(false)
      if (isLoading) {
        return;
      }
      if (!isAuthenticated) {
        loginWithRedirect({
          authorizationParams: {
            scope: "openid profile email",
            ui_locales: localStorage.getItem("savedLanguage") || "en"
          },
        })
      }
      setIsLogin(true)
      await getNumberOfPets();
    } catch (err: any) {
      console.log(err)
    }
  };

  useEffect(() => {
    if (isLoading ||
      !isAuthenticated && !pathToExclude.includes(previousLocation, 2)) {
      setDisplayLoading(true)
    }
  }, [navigate, isLoading, isAuthenticated])

  useEffect(() => {
    if (!pathToExclude.includes(location.pathname)) {
      if (numberOfPets < 1 && !["/application", "/profile"].includes(location.pathname)) {
        navigate("/application")
      }
      checkLogin();
    }
    setPreviousLocation(location.pathname);
    const handleResize = () => {
      if (window.innerWidth < 930) {
        setFooterVisibility(true)
      } else if (window.innerWidth > 930) {
        setFooterVisibility(false)
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [navigate, isLoading]);

  useEffect(() => {
    if (window.innerWidth < 930) {
      setFooterVisibility(true)
    } else if (window.innerWidth > 930) {
      setFooterVisibility(false)
    }
  }, [window.innerWidth]);

  useEffect(() => {
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  return (
    <>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/application' element={<ApplicationRoute />}>
          <Route index element={<Application />} />
          <Route path='calendar' element={<Calendar />} />
          <Route path='pets' element={<Pets />} />
          <Route path='health' element={<HealthRecord />} />
          <Route path='profile' element={<Profile />} />
        </Route>
        <Route path='/organization' element={<Organization />} />
      </Routes>
    </>
  );
}

export default App;
