import { BarChart, HighlightScope } from "@mui/x-charts";
import { useTranslation } from "react-i18next";

function BehaviorChart(props: any) {
    const {t} = useTranslation();
    const animalsInfo = props.animalsInfo;

    function getBehaviorStatus(species: string, status: string) {
        let statusNumber = 0;

        if (!animalsInfo)
            return 0;
        for (let animal of animalsInfo) {
            if (animal.species === species && animal.healthStatus.status === status) {
                statusNumber++;
            }
        }
        return statusNumber;
    }

    function getDogSerie() {
        return [getBehaviorStatus("dog", "positive"), getBehaviorStatus("dog", "warning"), getBehaviorStatus("dog", "danger")];
    }

    function getCatSerie() {
        return [getBehaviorStatus("cat", "positive"), getBehaviorStatus("cat", "warning"), getBehaviorStatus("cat", "danger")];
    }

    return (
        <BarChart
            loading={props.loading}
            borderRadius={4}
            xAxis={[
                {
                    id: 'barCategories',
                    data: [
                        t("organization.dashboard.chart.goodHealth"),
                        t("organization.dashboard.chart.warningHealth"),
                        t("organization.dashboard.chart.dangerHealth")
                    ],
                    scaleType: 'band',
                },
            ]}
            series={[
                {
                    label: t("organization.dashboard.chart.dogs"),
                    data: getDogSerie(),
                    highlightScope: {
                        highlight: "series",
                        fade: "global",
                    } as HighlightScope
                },
                {
                    label: t("organization.dashboard.chart.cats"),
                    data: getCatSerie(),
                    highlightScope: {
                        highlight: "series",
                        fade: "global",
                    } as HighlightScope
                }
            ]}
            width={500}
            height={300}
        />
    )
}

export default BehaviorChart;