import { Button, Form, Input, message, Modal } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { createOrganization } from "../../request/organization";
import { useAuth0 } from "@auth0/auth0-react";

function CreateOrganization(props: any) {
    const [modalOpen, setModalOpen] = useState(false);
    const [title, setTitle] = useState("");
    const {t} = useTranslation();
    const {getAccessTokenSilently} = useAuth0();

    async function handleValidation() {
        try {
            if (title.length === 0) {
                message.error(t("organization.settings.name-required"));
            } else {
                const accessToken = await getAccessTokenSilently();
                await createOrganization(accessToken, title);
                setTitle("");
                setModalOpen(false);
                props.renewOrganizations();
                message.success(t("organization.settings.create-success"));
            }
        } catch(err: any) {
            if (err === 409) {
                message.error(t("organization.settings.create-duplicate"));
            } else {
                message.error(t("organization.settings.create-failed"));
            }
        }
    }

    return (
        <>
            <Button type="primary" shape="round" size="large" style={{width: "min-content", alignSelf: "center"}} onClick={() => setModalOpen(true)}>{t("organization.settings.create-title")}</Button>
            <Modal
                open={modalOpen}
                title={t("organization.settings.create-title")}
                onCancel={() => setModalOpen(false)}
                onOk={handleValidation}
                cancelText={t("form.cancel")}
            >
                <Form.Item label={t("organization.settings.name")} required>
                    <Input value={title} onChange={(e) => setTitle(e.target.value)}/>
                </Form.Item>
                {/* See if email members have to be entered */}
            </Modal>
        </>
    )
}

export default CreateOrganization;