import React, { useEffect, useState } from 'react';
import { getEventsFromPets } from '../../request/events';
import { Affix, Button, Card, Checkbox, Divider, Flex, Select, Space, Typography } from 'antd';

import { EventDrawerAppointment } from '../../component/calendar/drawer/AppointmentDrawer';
import { EventDrawerTreatment } from '../../component/calendar/drawer/TreatmentDrawer';
import { Filter } from '../../component/calendar/Filter';
import GridView from '../../component/calendar/GridView';
import CalendarModal from './modal/CalendarModal';
import { customTheme } from '../../config/theme';
import { useGlobalState } from '../../state/globalState';
import { CalendarOutlined, ExclamationCircleOutlined, InfoCircleOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import PetAvatar, { assignPetAvatar } from '../pets/PetAvatar';
import { useAuth0 } from '@auth0/auth0-react';

function isARenewableTreatment(event: any, t: TFunction<'translate', undefined>) {
  const renewableTreatment = ["vaccine", "deworming", "anti-parasitic"];
  return renewableTreatment.includes(event.name);
}

function isImportant(event: any, t: TFunction<'translate', undefined>) {
  const today = new Date().setHours(0, 0, 0, 0);
  const eventDate = new Date(formatDate(event.date)).getTime();

  if (!event.to_renew)
    return false;
  return (eventDate - today < 604800000) && isARenewableTreatment(event, t);
}

const formatDate = (dateToFormat: string) => {
  const [date, time] = dateToFormat.split(' ');
  const [day, month, year] = date.split('-');

  return `${year}-${month}-${day}`;
}

function addInEvents(event: any, key: any, events: any) {
  if (!events[key]) {
    events[key] = [];
  }
  events[key].push(event);
}

function removeOldEvents(sortedGroupedKeys: string[], sortedGroupedEvents: any, t: TFunction<'translate', undefined>) {
  const today = new Date().setHours(0, 0, 0, 0);
  const pastEvents: any = {};
  const upcomingEvents: any = {};

  for (const key of sortedGroupedKeys) {
    const eventsForMonth = sortedGroupedEvents[key];

    for (const event of eventsForMonth) {
      const eventDate = new Date(formatDate(event.date)).getTime();

      event.important = isImportant(event, t);
      if (eventDate < today) {
        event.showAffiliate = (event.to_link && event.name == "vaccine");
        if (event.important || (event.to_link && event.name == "vaccine"))
          addInEvents(event, key, upcomingEvents);
        else
          addInEvents(event, key, pastEvents);
      } else {
        event.showAffiliate = event.to_link;
        if (isARenewableTreatment(event, t) && !event.to_renew)
          addInEvents(event, key, pastEvents);
        else
          addInEvents(event, key, upcomingEvents);
      }
    }
  }
  return { pastEvents, upcomingEvents }
}

function groupEventsByMonth(events: any, t: TFunction<'translate', undefined>) {
  const grouped = events.reduce((acc: any, event: any) => {
    if (!event.date) {
      return acc;
    }
    const [day, month, year] = (event.date.split(' ')[0]).split('-')
    const monthKey = `${year}-${String(month).padStart(2, '0')}`;

    if (!acc[monthKey]) {
      acc[monthKey] = [];
    }
    acc[monthKey].push(event);

    return acc;
  }, {});

  const sortedGroupedKeys = Object.keys(grouped).sort((a, b) => {
    return new Date(a).getTime() - new Date(b).getTime();
  });

  const formatDate = (dateToFormat: string) => {
    const [date, time] = dateToFormat.split(' ');
    const [day, month, year] = date.split('-');

    if (time) {
      return `${month}-${day}-${year} ${time}`;
    }
    return `${month}-${day}-${year}`;
  }

  const sortedGroupedEvents = sortedGroupedKeys.reduce((acc: any, key) => {
    grouped[key].sort((a: any, b: any) => new Date(formatDate(a.date)).getTime() - new Date(formatDate(b.date)).getTime());

    acc[key] = grouped[key];
    return acc;
  }, {});

  return removeOldEvents(sortedGroupedKeys, sortedGroupedEvents, t);
}

function displayMonthYear(date: string, t: TFunction<"translation", undefined>) {
  const months = [
    t("month.january"), t("month.february"), t("month.march"), t("month.april"), t("month.may"), t("month.june"),
    t("month.july"), t("month.august"), t("month.september"), t("month.october"), t("month.november"), t("month.december")
  ];
  const dateSplit = date.split("-")
  const month: number = Number(dateSplit[1]);
  const year: string = dateSplit[0];

  return `${months[month - 1]} ${year}`
}

function getTranslatedEventType(type: string, t: TFunction<'translate', undefined>) {
  const appointmentTranslateSearch = `appointment-creation.appointment-type.${type}`
  const treatmentReminderTranslateSearch = `treatment-reminder-creation.treatment-reminder-type.${type}`

  if (appointmentTranslateSearch != t(appointmentTranslateSearch)) {
    return t(appointmentTranslateSearch);
  } else if (treatmentReminderTranslateSearch != t(treatmentReminderTranslateSearch)) {
    return t(treatmentReminderTranslateSearch);
  }
  return type;
}

function CalendarDisplay() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const {t} = useTranslation();

  const [reRender, setReRender] = useGlobalState('reRender');
  const [footerVisibility, setFooterVisibility] = useGlobalState('footerVisibility');

  const [event, setEvent] = useState([""]);

  const [drawerEvent, setDrawerEvent] = useState();
  const [openStateDrawer, setOpenStateDrawer] = useState<boolean>(false);

  const [isModalVisible, setModalVisibility] = useState(false);
  const [selectEvent, setSelectEvent] = useState<"treatment-reminder" | "appointment">("appointment");

  const [openSelectButton, setOpenSelectButton] = useState<boolean>(false);

  let eventsByMonth = groupEventsByMonth(event, t);

  const [seePastEvents, setSeePastEvents] = useState(false);
  const [filter, setFilter] = useState<string[]>([]);
  const [gridView, setGridView] = useState<boolean>(false);
  const [userPets, setUserPets] = useGlobalState('userPets');

  const getAccessToken = async () => {
    if (isAuthenticated) {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
          scope: "openid profile email",
        },
      });
      return accessToken;
    }
    return "";
  }

  const renewEvents = async () => {
    const petQuery = userPets.map((pet: any) => pet._id);
    await setEvent(await assignPetAvatar(await getEventsFromPets(await getAccessToken(), petQuery), "pet.pet_id", "pet.avatar", true));
  }

  const handleModalSubmit = async (submit: boolean) => {
    setModalVisibility(false);
    if (submit) {
      renewEvents()
    }
  }

  const onDrawerClose = (reRender: boolean) => {
    setTimeout(() => {
      setOpenStateDrawer(false)
    }, 250);
    if (reRender) {
      renewEvents();
    }
  }

  const handleFilterChange = (filterParam: string[]) => {
    setFilter(filterParam);
  }

  const checkFilter = (event: any) => {
    if ((filter.includes(event.type) || filter.includes(event.pet.name)) || !filter[0]) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    renewEvents();
  }, [reRender])

  const EventDisplay = (events: any) => {
    return (
      <>
        {Object.keys(events.events).map((date: any, index: number) => {
          const filteredEvents = events.events[date].filter((event: any) => checkFilter(event));

          if (filteredEvents.length === 0) {
            return null;
          }

          return (
            <div style={{ maxWidth: '600px', width: '100%' }} key={index}>
              <Divider orientation='left' orientationMargin="0" className='event-date' style={{ fontSize: '20px', marginRight: '15px', marginLeft: '15px' }}>
                {displayMonthYear(date, t)}
              </Divider>
              {filteredEvents.map((event: any, key: any) => (
                <div key={key}>
                  <Card
                    key={key}
                    style={{
                      marginRight: '15px', marginLeft: '15px', marginBottom: '20px',
                      cursor: 'pointer',
                      boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
                    }}
                    onClick={() => {
                      setDrawerEvent(event);
                      setOpenStateDrawer(true);
                    }}
                    hoverable
                  >
                    <Flex justify='start' align="center" gap={"small"}>
                      <Flex vertical align='center' justify='center'>
                        <PetAvatar species={event.pet.species} url={event.pet.avatar} iconSize={{width: "30px", height: "30px"}}/>
                        <Typography.Text style={{ fontSize: '14px' }}>{event.pet.name}</Typography.Text>
                      </Flex>
                      <Divider type='vertical' style={{ backgroundColor: customTheme.colorPrimary, height: '32px', width: '2px', border: 'none' }}></Divider>
                      <Typography.Text style={{ fontSize: '17px' }}>{(event.date).split(" ")[0]} - {getTranslatedEventType(event.name, t)}</Typography.Text>
                      <Flex style={{ position: 'absolute', right: '10px' }} gap={5}>
                        {event.showAffiliate &&
                          <InfoCircleOutlined
                            style={{
                              color: 'blue',
                              fontSize: '18px',
                            }}
                          />
                        }
                        {event.important && (
                          <ExclamationCircleOutlined
                            style={{
                              color: 'red',
                              fontSize: '18px',
                            }}
                          />
                        )}
                      </Flex>
                    </Flex>
                  </Card>
                </div>
              ))}
            </div>
          );
        })}
      </>
    );
  };

  return (
      <div className="event-container" style={{ height: '100%' }}>
        <Flex justify='end' style={{ flexDirection: 'row', marginTop: '5px', position: 'fixed', top: '85px', right: '0px', zIndex: 900, marginRight: '10px' }} gap={10}>
          {!gridView &&
            <Filter filterChange={handleFilterChange} filter={filter}/>
          }
          {!footerVisibility &&
            <Button
              onClick={() => setGridView(!gridView)}
            >
              {gridView ? <UnorderedListOutlined /> : <CalendarOutlined />}
            </Button>
          }
        </Flex>
        {isModalVisible && <CalendarModal selectedEvent={selectEvent} onSubmit={handleModalSubmit}/>}
        <div style={{ height: '100%' }}>
          {gridView ? (
            <GridView events={eventsByMonth} renew={renewEvents} filter={filter}/>
          ) : (
            <>
            <div style={{ marginTop: '20px' }}/>
            {eventsByMonth &&
              <Flex justify='center' style={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}>
                {Object.keys(eventsByMonth.pastEvents).length > 0 &&
                  <Flex justify='center' style={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}>
                    {seePastEvents &&
                      <EventDisplay events={eventsByMonth.pastEvents}/>
                    }
                    <Flex style={{ width: '100%' }}>
                      <Divider style={{ fontSize: '20px'}}>
                        {<Button onClick={() => setSeePastEvents(!seePastEvents)}>
                          {seePastEvents ? t("event-page.past-event-on") : t("event-page.past-event-off")}
                        </Button>}
                      </Divider>
                    </Flex>
                  </Flex>
                }
                <EventDisplay events={eventsByMonth.upcomingEvents}/>
              </Flex>
            }
            </>
          )}
        </div>
        {openStateDrawer &&
          <>
            {drawerEvent && (drawerEvent as any)?.type === 'A' ? (
              <EventDrawerAppointment onClose={onDrawerClose} event={drawerEvent} renew={renewEvents} events={event} openEvent={setDrawerEvent}/>
            ) : (
              <EventDrawerTreatment onClose={onDrawerClose} event={drawerEvent} renew={renewEvents} events={event} openEvent={setDrawerEvent}/>
            )}
          </>
        }
        <Flex justify='center' style={{ flexDirection: 'column', bottom: `calc(${footerVisibility ? '50px' : '0px'} + 25px)`, right: '25px', position: 'fixed' }} gap={5}>
          <Flex
            style={{
              flexDirection: 'column',
              maxHeight: openSelectButton ? '300px' : '0',
              overflow: 'hidden',
              transition: 'max-height 0.5s ease',
            }}
            gap={5}
          >
            <Button
              className='select-btn-selection'
              style={{ fontSize: '18px', width: '210px', textAlign: 'start' }}
              type='default'
              onClick={() => {
                setSelectEvent('treatment-reminder');
                setModalVisibility(true);
                setOpenSelectButton(false);
              }}
            >
              {t("event-page.treatment-reminder")}
            </Button>
            <Button
              className='select-btn-selection'
              style={{ fontSize: '18px', width: '210px', alignContent: 'start' }}
              type='default'
              onClick={() => {
                setSelectEvent('appointment');
                setModalVisibility(true);
                setOpenSelectButton(false);
              }}
            >
              {t("event-page.appointment")}
            </Button>
          </Flex>
          <Button style={{ fontSize: '18px', width: '210px' }} type='primary' onClick={() => setOpenSelectButton(!openSelectButton)}>{t("event-page.add-event")}</Button>
        </Flex>
      </div>
  );
}

export { isARenewableTreatment, isImportant, displayMonthYear, getTranslatedEventType };

export default CalendarDisplay;
