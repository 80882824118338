import React, { useEffect, useState } from 'react';
import { Card, Divider, Flex, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { useGlobalState } from '../../../state/globalState';
import { getEventsFromPets } from '../../../request/events';
import { EventDrawerAppointment } from '../drawer/AppointmentDrawer';
import { EventDrawerTreatment } from '../drawer/TreatmentDrawer';
import { customTheme } from '../../../config/theme';
import { getTranslatedEventType, isImportant } from '../CalendarDisplay';
import PetAvatar, { assignPetAvatar } from '../../pets/PetAvatar';
import { useAuth0 } from '@auth0/auth0-react';

function formatDateToIso(dateToFormat: string) {
  const [date, time] = dateToFormat.split(' ');
  const [day, month, year] = date.split('-');

  return `${year}-${month}-${day}T${time || "00:00"}:00Z`;
}

function ComingEvents() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const {t} = useTranslation();

  const [reRender, setReRender] = useGlobalState('reRender');

  const [events, setEvents] = useState([]);
  const [commingEvent, setCommingEvent] = useState([]);
  const [drawerEvent, setDrawerEvent] = useState();
  const [openStateDrawer, setOpenStateDrawer] = useState<boolean>(false);
  const [userPets, setUserPets] = useGlobalState('userPets');

  const getAccessToken = async () => {
    if (isAuthenticated) {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
          scope: "openid profile email",
        },
      });
      return accessToken;
    }
    return "";
  }

  const onDrawerClose = (reRender: boolean) => {
    setTimeout(() => {
      setOpenStateDrawer(false)
    }, 250);
    if (reRender) {
      getComingEvent();
    }
  }

  const getComingEvent = async () => {
    const petsQuery = userPets.map((pet: any) => pet._id);
    const response = await assignPetAvatar(await getEventsFromPets(await getAccessToken(), petsQuery), "pet.pet_id", "pet.avatar", true);

    response.sort((a: any, b: any) => new Date(formatDateToIso(a.date)).getTime() - new Date(formatDateToIso(b.date)).getTime());
    setEvents(response);
    let filteredResponse = response.filter((event: any) => new Date(formatDateToIso(event.date || "")).getTime() >= new Date().getTime());

    filteredResponse = filteredResponse.map((event: any) => {
      event.important = isImportant(event, t);
      return event;
    });

    setCommingEvent(filteredResponse);
  }

  useEffect(() => {
    getComingEvent()
  }, [reRender]);

  useEffect(() => {
    commingEvent.forEach((event: any) => {
      if (event.length === 0)
        setCommingEvent((prevEvents) => prevEvents.filter((e: any) => e !== event));
    });
  }, [commingEvent]);

  return (
    <>
      {openStateDrawer &&
          <>
              {drawerEvent && (drawerEvent as any)?.type === 'A' ? (
                  <EventDrawerAppointment onClose={onDrawerClose} event={drawerEvent} renew={getComingEvent} events={events} openEvent={setDrawerEvent}/>
              ) : (
                  <EventDrawerTreatment onClose={onDrawerClose} event={drawerEvent} renew={getComingEvent} events={events} openEvent={setDrawerEvent}/>
              )}
          </>
      }
      {commingEvent.length !== 0 &&
        <Flex style={{ margin: '0 auto', maxWidth: '620px', marginTop: '10px', flexDirection: 'column' }}>
          <Typography.Title level={5} style={{ textAlign: 'center', margin: 0 }}>{t("event-page.coming-event")}</Typography.Title>
          {commingEvent.slice(0, 3).map((event: any, key: any) => (
            <Card
                key={key}
                style={{
                  height: 'auto', cursor: 'pointer', minWidth: '100px', textAlign: 'left', margin: '10px', marginRight: '15px', marginLeft: '15px',
                  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
                }}
                onClick={() => {
                    setDrawerEvent(event);
                    setOpenStateDrawer(true)
                }}
                hoverable
            >
              <Flex justify='start' style={{ flexDirection: 'row', alignItems: 'center' }} gap={10}>
                <Flex vertical align='center' justify='center'>
                  <PetAvatar species={event.pet.species} url={event.pet.avatar} iconSize={{width: "30px", height: "30px"}}/>
                  <Typography.Text style={{ fontSize: '14px' }}>{event.pet.name}</Typography.Text>
                </Flex>
                <Divider type='vertical' style={{ backgroundColor: customTheme.colorPrimary, height: '32px', width: '2px', border: 'none' }}></Divider>
                <Typography.Text style={{ fontSize: '17px' }}>{(event?.date).split(" ")[0]} - {getTranslatedEventType(event?.name, t)}</Typography.Text>
                {event.important && (
                  <ExclamationCircleOutlined
                    style={{
                      color: 'red',
                      fontSize: '18px',
                      position: 'absolute',
                      right: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)'
                    }}
                  />
                )}
              </Flex>
            </Card>
          ))}
          <Flex style={{ marginTop: '25px', marginBottom: '15px', width: '50%', alignSelf: 'center', height: '2px' }}>
            <Divider style={{ margin: 0, backgroundColor: 'rgba(173, 174, 175, 0.2)' }}/>
          </Flex>
        </Flex>
      }
    </>
  );
}

export {formatDateToIso}

export default ComingEvents;
