import { Divider, Flex, Skeleton, Typography } from "antd";
import "./OrganizationSummary.css";
import BehaviorChart from "../../../component/organization/charts/BehaviorChart";
import VaccineChart from "../../../component/organization/charts/VaccineChart";
import ClassmentList from "../../../component/organization/charts/ClassmentList";
import OrganizationSelect from "../../../component/organization/OrganizationsSelect";
import CreateOrganization from "../../../component/organization/CreateOrganization";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

function OrganizationSummary(props: any) {
    const organizations = props.organizations;
    const selectedOrganization = props.organization;
    const organizationInfo = props.organizationInfo;
    const [pointRanking, setPointRanking] = useState([]);
    const [weightRanking, setWeightRanking] = useState([]);
    const [activityRanking, setActivityRanking] = useState([]);

    const {t} = useTranslation();

    function getVaccineLate() {
        let late = 0;

        if (!organizationInfo?.animals)
            return 0;
        for (let animal of organizationInfo.animals) {
            if (animal.treatments.lateVaccine > 0) {
                late++;
            }
        }
        return late;
    }

    function getNoVetNumber() {
        let noVet = 0;

        if (!organizationInfo?.animals)
            return 0;
        for (let animal of organizationInfo.animals) {
            if (animal.vetAppointments.length === 0) {
                noVet++;
            }
        }
        return noVet;
    }

    function getSpeciesNumber(species: string) {
        let speciesNumber = 0;

        if (!organizationInfo?.animals)
            return 0;
        for (let animal of organizationInfo.animals) {
            if (animal.species === species) {
                speciesNumber++;
            }
        }
        return speciesNumber;
    }

    function getPointsRanking() {
        if (!organizationInfo?.animals)
            return setPointRanking([]);
        const animalsSorted = organizationInfo.animals.sort((a: any, b: any) => a.points.score - b.points.score);
        const sortedResults = animalsSorted.map((animal: any) => {
            return `${animal.name} : ${animal.points.score}`;
        });
        setPointRanking(sortedResults);
    }

    function getWeightRanking() {
        if (!organizationInfo?.animals)
            return setWeightRanking([]);
        const animalsSorted = organizationInfo.animals.sort((a: any, b: any) => a.weightStatus.differencePourcentage - b.weightStatus.differencePourcentage);
        const sortedResults = animalsSorted.map((animal: any) => {
            return `${animal.name} : ${animal.weightStatus.weight}kg`;
        });
        setWeightRanking(sortedResults);
    }

    function getActivityRanking() {
        if (!organizationInfo?.animals)
            return setActivityRanking([]);
        const animalsSorted = organizationInfo.animals.sort((a: any, b: any) => a.activityStatus.time - b.activityStatus.time);
        const sortedResults = animalsSorted.map((animal: any) => {
            const hours = Math.floor(animal.activityStatus.time / 3600);
            const minutes = Math.floor((animal.activityStatus.time % 3600) / 60);
            return `${animal.name} : ${hours}h ${minutes}min`;
        });
        setActivityRanking(sortedResults);
    }

    useEffect(() => {
        getPointsRanking();
        getWeightRanking();
        getActivityRanking();
    }, [organizationInfo]);

    if (selectedOrganization === undefined) {
        return (
            <>
                <OrganizationSelect organization={selectedOrganization} organizations={organizations} onOrganizationChange={props.onOrganizationChange}/>
                <Flex vertical align="center" justify="center" style={{height: "100%"}}>
                    <Typography.Title level={3}>{t("organization.no-organization")}</Typography.Title>
                    <Typography.Text>{t("organization.no-organization-description")}</Typography.Text>
                    <Divider>{t("linkers.or")}</Divider>
                    <CreateOrganization renewOrganizations={props.renewOrganizations}/>
                </Flex>
            </>
        )
    }

    if (props.loading) {
        return (
            <Skeleton active />
        )
    }

    return (
        <Flex vertical gap={"medium"}>
            <OrganizationSelect organization={selectedOrganization} organizations={organizations} onOrganizationChange={props.onOrganizationChange}/>
            <Flex align="center" gap={"small"} justify="space-between" className="firstSummaryContent">
                <Flex align="center" gap={"large"} className="behaviorSummary">
                    <Flex vertical>
                        <Typography.Title level={3}>{t("organization.dashboard.comportments")}</Typography.Title>
                        <Typography.Text>{getSpeciesNumber("dog")} {t("organization.dashboard.chart.dogs")}</Typography.Text>
                        <Typography.Text>{getSpeciesNumber("cat")} {t("organization.dashboard.chart.cats")}</Typography.Text>
                    </Flex>
                    <BehaviorChart animalsInfo={organizationInfo.animals}/>
                </Flex>
                <Flex className="vaccineSummary" gap={"middle"} align="center">
                    <Flex vertical gap={"small"} style={{width: "50%"}} align="center">
                        <Typography.Title level={3}>{t("organization.dashboard.vaccine")}</Typography.Title>
                        <VaccineChart value={getNoVetNumber()} valueMax={organizationInfo.animals?.length} />
                        <Typography.Title level={4}>{t("organization.dashboard.late-vaccine")}</Typography.Title>
                    </Flex>
                    <Divider type="vertical" style={{height: "100px"}}/>
                    <Flex vertical gap={"small"} style={{width: "50%"}} align="center">
                        <Typography.Title level={3}>{t("organization.dashboard.vet-appointments")}</Typography.Title>
                        <VaccineChart value={getVaccineLate()} valueMax={organizationInfo.animals?.length}/>
                        <Typography.Title level={4} style={{textAlign: "center"}}>{t("organization.dashboard.no-appointments")}</Typography.Title>
                    </Flex>
                </Flex>
            </Flex>
            <Flex className="secondSummaryContent" align="start" justify="space-between">
                <Flex className="pointSummary" vertical align="center">
                    <Typography.Title level={4}>{t("organization.dashboard.summary.points")}</Typography.Title>
                    <ClassmentList firstPartTitle={t("organization.dashboard.summary.top-5")} secondPartTitle={t("organization.dashboard.summary.last-5")} firstPartContent={pointRanking.slice(0, 5)} secondPartContent={pointRanking.reverse().slice(0, 5)}/>
                </Flex>
                <Flex className="weightSummary" vertical align="center">
                    <Typography.Title level={4}>{t("organization.dashboard.summary.weights-warning")}</Typography.Title>
                    <ClassmentList firstPartTitle={t("organization.dashboard.summary.overweight-risk")} secondPartTitle={t("organization.dashboard.summary.underweight-risk")} firstPartContent={weightRanking.slice(0, 5)} secondPartContent={weightRanking.reverse().slice(0, 5)}/>
                </Flex>
                <Flex className="activitySummary" vertical align="center">
                    <Typography.Title level={4}>{t("organization.dashboard.summary.activity-warning")}</Typography.Title>
                    <ClassmentList firstPartTitle={t("organization.dashboard.summary.top-5")} secondPartTitle={t("organization.dashboard.summary.last-5")} firstPartContent={activityRanking.slice(0, 5)} secondPartContent={activityRanking.reverse().slice(0,5)}/>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default OrganizationSummary;