import { SettingOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Card, List, Button, Modal, Input, Form, message, Typography, Popconfirm } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { addMemberInOrganization, addPetOwnerInOrganization, deleteOrganization, removeMemberInOrganization, removePetOwnerInOrganization, updateOrganization } from "../../request/organization";

function OrganizationCard({ organization, renewOrganizations, onOrganizationChange, renewOrganizationInfo, loading }: { organization: any, renewOrganizations: any, onOrganizationChange: any, renewOrganizationInfo: any, loading: boolean }) {
    const [members, setMembers] = useState(organization.members || []);
    const [petOwners, setPetOwners] = useState(organization.pet_owners || []);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [settingsModalOpen, setSettingsModalOpen] = useState(false);
    const [modalType, setModalType] = useState("");
    const [newTitle, setNewTitle] = useState(organization.name);
    const [form] = Form.useForm();
    const {t} = useTranslation();

    const {getAccessTokenSilently} = useAuth0();

    const showModal = (type: string) => {
        setModalType(type);
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        try {
            await form.validateFields();
            const values = form.getFieldsValue();
            const accessToken = await getAccessTokenSilently();
            if (modalType === "member") {
                await addMemberInOrganization(accessToken, organization._id, values.email);
                message.success(t("organization.settings.add-member-success"));
            } else {
                await addPetOwnerInOrganization(accessToken, organization._id, values.email);
                message.success(t("organization.settings.add-pet-owner-success"));
            }
            form.resetFields();
            setIsModalOpen(false);
            renewOrganizationInfo();
        } catch(err) {
            console.log(err);
            message.error(t("organization.settings.add-failed"));
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
    };

    const handleDelete = async (type: string, email: string) => {
        try {
            const accessToken = await getAccessTokenSilently();
            if (type === "member") {
                await removeMemberInOrganization(accessToken, organization._id, email);
                message.success(t("organization.settings.remove-member-success"));
            } else {
                await removePetOwnerInOrganization(accessToken, organization._id, email);
                message.success(t("organization.settings.remove-pet-owner-success"));
            }
            renewOrganizationInfo();
        } catch(err) {
            message.error(t("organization.settings.remove-failed"));
        }
    };

    async function handleSettingsValidation() {
        try {
            if (newTitle.length > 0 && newTitle !== organization.title) {
                const accessToken = await getAccessTokenSilently();
                await updateOrganization(accessToken, organization._id, newTitle);
                renewOrganizations();
            } else {
                message.error(t("organization.settings.name-required"));
                return;
            }
        } catch(err) {
            message.error(t("organization.settings.update-failed"));
        }
        setSettingsModalOpen(false);
    }

    async function handleDeletion() {
        try {
            const accessToken = await getAccessTokenSilently();
            await deleteOrganization(accessToken, organization._id);
            setSettingsModalOpen(false);
            message.success(t("organization.settings.delete-success"));
            renewOrganizations();
            onOrganizationChange({});
        } catch(err) {
            if (err === 403) {
                message.error(t("organization.settings.delete-forbidden"));
            } else {
                message.error(t("organization.settings.delete-failed"));
            }
        }
    }

    function getModalTitle() {
        return modalType === "member" ? t("organization.settings.add-member") : t("organization.settings.add-pet-owner");
    }

    useEffect(() => {
        setMembers(organization.members || []);
        setPetOwners(organization.pet_owners || []);
    }, [organization]);

    return (
        <Card
            title={organization.name}
            extra={<Button icon={<SettingOutlined/>} onClick={() => setSettingsModalOpen(true)}type="default">{t("organization.settings.settings")}</Button>}
        >
            <List
                header={<Typography.Title level={3}>{t("organization.settings.members")}</Typography.Title>}
                dataSource={members}
                renderItem={(item: any) => (
                    <List.Item
                        actions={[<Button type="link" danger onClick={() => handleDelete("member", item.email)}>{t("organization.settings.remove")}</Button>]}
                    >
                        <b>{item.firstname} {item.lastname}</b> ({item.email})
                    </List.Item>
                )}
                loading={loading}
            />
            <Button type="primary" onClick={() => showModal("member")} style={{ marginBottom: 10, marginTop: 10 }}>
                {t("organization.settings.add-member")}
            </Button>
            <List
                header={<Typography.Title level={3}>{t("organization.settings.pet-owner")}</Typography.Title>}
                dataSource={petOwners}
                renderItem={(item: any) => (
                    <List.Item
                        actions={[<Button type="link" danger onClick={() => handleDelete("petOwner", item.email)}>{t("organization.settings.remove")}</Button>]}
                    >
                        <b>{item.firstname} {item.lastname}</b> ({item.email})
                    </List.Item>
                )}
                loading={loading}
            />
            <Button type="primary" onClick={() => showModal("petOwner")} style={{ marginBottom: 10, marginTop: 10 }}>
                {t("organization.settings.add-pet-owner")}
            </Button>
            <Modal title={getModalTitle()} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} cancelText={t("form.cancel")}>
                <Form form={form} layout="vertical">
                    <Form.Item name="email" label="Email" rules={[{ required: true, message: t("organization.settings.email-required") }]}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal title={t("organization.settings.settings-title")} open={settingsModalOpen} onOk={handleSettingsValidation} onCancel={() => setSettingsModalOpen(false)} cancelText={t("form.cancel")}>
                <Form form={form} layout="vertical">
                    <Form.Item name="title" label={t("organization.settings.name")} rules={[{ required: true, message: t("organization.settings.name-required") }]}>
                        <Input value={newTitle} onChange={(e) => setNewTitle(e.target.value)}/>
                    </Form.Item>
                    <Popconfirm
                        title={t("organization.settings.delete-confirm")}
                        onConfirm={handleDeletion}
                        okText={t("family.yes")}
                        cancelText={t("family.no")}
                    >
                        <Button danger>{t("organization.settings.delete")}</Button>
                    </Popconfirm>
                </Form>
            </Modal>
        </Card>
    );
}

export default OrganizationCard;
