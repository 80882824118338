import { Link } from 'react-router-dom'
import { Menu, ConfigProvider, Button, Flex } from 'antd';

import './navBar.css'
import { useGlobalState } from '../../state/globalState'
import SelectPet from './SelectPet';
import LeaveConfirm from '../confirm/LeaveConfirm';

function NavBar() {
  const [reRender, setReRender] = useGlobalState('reRender');

  const homePath: string[] = ["/", "/home"];
  const pathToExclude: string[] = ["/login", "/register", ...homePath];

  return (
    <ConfigProvider
        theme={{
          components: {
            Menu: {
              colorPrimary: 'white',
              darkItemColor: 'black',
              darkItemSelectedColor: 'black',
              darkItemHoverColor: 'black'
            },
            Button: {
              defaultHoverColor: 'black',
            }
        }
      }}
    >
      {!pathToExclude.includes(window.location.pathname) ? (
        <Menu
          theme='dark'
          mode="horizontal"
          style={{
            display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center',
            fontSize: '18px', width: '100%', height: '100%', padding: '0px 30px',
            borderBottom: '1px solid rgb(236, 236, 236)', backgroundColor: 'white' }}
        >
          <Menu.Item className='navbar-item-left' key={1} style={{ marginLeft: '30px', padding: 0, textAlign: 'left', minWidth: 0}}>
            <LeaveConfirm
              path="/"
              textPath="confirm.leave"
            >
              <img
                className='navbar-app-logo'
                src="assets/images/AppLogoBlue.png"
                width={70}
                alt=""
              />
            </LeaveConfirm>
          </Menu.Item>
          {!homePath.includes(window.location.pathname) &&
            <Menu.Item className='navbar-item-right' key={2} style={{ marginLeft: 'auto', marginRight: '30px', padding: 0, textAlign: 'right', minWidth: 0 }}>
              <SelectPet
                onChange={() => setReRender(new Date())}
                changeCookies={true}
                className='select-pet'
                height='45px'
              />
            </Menu.Item>
          }
        </Menu>
      ) : (
        null
      )}
    </ConfigProvider>
  );
}

export default NavBar;
