import Layout, { Content, Footer, Header } from "antd/es/layout/layout";
import NavBar from "../component/navBar/NavBar";
import { Flex } from "antd";
import SideBar from "../component/sideBar/SideBar";
import { useGlobalState } from "../state/globalState";
import { customTheme } from "../config/theme";
import Sider from "antd/es/layout/Sider";
import { Outlet } from "react-router-dom";

function ApplicationRoute() {
    const [footerVisibility, setFooterVisibility] = useGlobalState('footerVisibility');

    return (
        <Layout style={{width: '100dvw'}}>
            <Header style={{
                    width: '100vw', height: '80px',
                    position: 'sticky', top: 0, zIndex: 1, padding: 0,
                }}
            >
                <NavBar/>
            </Header>
            <Layout>
                {footerVisibility == false &&
                    <Sider width={'250px'}>
                        <SideBar mode='inline' display='sider'/>
                    </Sider>
                }
                <Content style={{
                    backgroundColor: customTheme.colorBackground,
                    height: `calc(100dvh - ${footerVisibility ? '140px' : '80px'})`,
                    overflow: 'hidden', overflowY: 'scroll'
                    }}
                >
                    <Outlet/>
                </Content>
            </Layout>
            {footerVisibility === true &&
                <Footer style={{
                        width: '100vw', height: '60px', position: 'fixed',
                        padding: 0, bottom: 0, zIndex: 999, backgroundColor: 'white'
                    }}
                >
                    <Flex justify='center' style={{ height: '100%', alignItems: 'center', borderTop: '1px solid rgb(236, 236, 236)' }}>
                        <SideBar mode='horizontal' display='footer'/>
                    </Flex>
                </Footer>
            }
        </Layout>
    )
}

export default ApplicationRoute;