import { Divider, Drawer, Flex, Popover, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getOrganizationPetInfo } from "../../../request/organization";
import { useAuth0 } from "@auth0/auth0-react";
import { customTheme } from "../../../config/theme";
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { CartesianGrid, Line, LineChart, Tooltip, ResponsiveContainer, XAxis, ReferenceArea } from "recharts";
import SimpleScoreDisplay from "./SimpleScoreDisplay";
import OrganizationBehaviorsDisplay from "./OrganizationBehaviorsDisplay";

interface Analysis {
    status: 'positive' | 'warning' | 'danger' | 'advice';
    messageFr: string;
    messageEn: string;
}

function PetOrganizationDrawer(props: any) {
    const selectedPet = props.selectedPet;
    const { t } = useTranslation();
    const [selectedPetInfo, setSelectedPetInfo] = useState<any>();
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    async function getAccessToken() {
        if (isAuthenticated) {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
                    scope: "openid profile email",
                },
            });
            return accessToken;
        }
        return "";
    }

    async function getPetInfos() {
        try {
            const accessToken = await getAccessToken();
            const petInfos = await getOrganizationPetInfo(accessToken, props.organizationId, selectedPet.id);
            if (petInfos)
                setSelectedPetInfo(petInfos);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (!selectedPet)
            return;
        getPetInfos();
    }, [selectedPet]);

    function getGender(gender: any) {
        if (!gender)
            return "";
        if (gender == "Mâle") {
            return t("pet-profile.male");
        }
        return t("pet-profile.female");
    }

    function getScoreStatus(status: string) {
        return status === "low" ? t("organization.animals.drawer.low-score") : t("organization.animals.drawer.good-score");
    }

    function getTreatmentStatus(status: string) {
        return t(`organization.animals.drawer.${status}`);
    }

    if (!selectedPet || !selectedPetInfo) {
        return <></>;
    }

    function renderAnalysisMessage(analysisBehavior: Analysis | null) {
        if (!analysisBehavior) return null;

        const iconMapping = {
            positive: <CheckCircleOutlined style={{ color: 'green' }} />,
            advice: <InfoCircleOutlined style={{ color: 'blue' }} />,
            warning: <WarningOutlined style={{ color: 'orange' }} />,
            danger: <CloseCircleOutlined style={{ color: 'red' }} />,
        };

        const statusLabels = {
            positive: t("behaviors.analysis.positive"),
            advice: t("behaviors.analysis.advice"),
            warning: t("behaviors.analysis.warning"),
            danger: t("behaviors.analysis.danger"),
        };

        const messageDisplayed = localStorage.getItem("savedLanguage") === "fr" ? analysisBehavior.messageFr : analysisBehavior.messageEn;

        return (
            <Flex align='top' justify='flex-start' gap={'small'} style={{marginBottom: '10px'}}>
                <p style={{fontSize: "15px"}} className='behavior-analysis-message'>
                    {iconMapping[analysisBehavior.status]}
                </p>
                <p style={{fontSize: "15px"}} className='behavior-analysis-message'>
                    <b>{statusLabels[analysisBehavior.status]}: </b>
                    {messageDisplayed}
                </p>
            </Flex>
        );
    };

    const CustomTooltipWeight = ({ active, payload, label }: any) => {
        if ((active && payload && payload.length) && selectedPetInfo.weights != null) {
            const { _id, created_at } = payload[0].payload;
            const weightNumber = selectedPetInfo.weights.find((_weight: any) => _weight._id === _id)?.weight;

            return (
                <Flex className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc', flexDirection: 'column' }}>
                    <Typography.Text>{`${created_at}`}</Typography.Text>
                    <Typography.Text style={{ color: customTheme.colorPrimary }}>{`${t("weight-section.weight-tooltip")}: ${weightNumber}`}</Typography.Text>
                </Flex>
            );
        }
        return null;
    };

    function ScoreDetails({score}: any) {
        return (
            <Flex vertical>
                <Typography.Text><b>{score.behaviorValidation}</b> {t("organization.animals.drawer.behavior-validation")}</Typography.Text>
                <Typography.Text><b>{score.activityValidation}</b> {t("organization.animals.drawer.activity-validation")}</Typography.Text>
                <br/>
                <Typography.Text><i>Min. <b>4</b> {t("organization.animals.drawer.min-sentence-first")} <b>5</b> {t("organization.animals.drawer.min-sentence-second")}</i></Typography.Text>
            </Flex>
        );
    }

    const showRefArea = selectedPet.age > 1;

    return (
        <Drawer
            open={props.open}
            onClose={props.onClose}
            title={selectedPet.name}
            width={650}
        >
            <Flex align="center" justify="space-between">
                <Flex vertical>
                    <Typography.Title level={4}>{t("organization.animals.drawer.general-informations")}</Typography.Title>
                    <Typography.Text>{t("pet-profile.birth-date")} : <b>{selectedPetInfo.birth_date}</b></Typography.Text>
                    <Typography.Text>{t("pet-profile.breed")} : <b>{selectedPetInfo.breed}</b></Typography.Text>
                    <Typography.Text>{t("pet-profile.gender")} : <b>{getGender(selectedPetInfo.gender)}</b></Typography.Text>
                </Flex>
                <Flex vertical align="end">
                    <Typography.Text>{t("organization.animals.drawer.month-score")}</Typography.Text>
                    <SimpleScoreDisplay score={selectedPetInfo.points.score}/>
                    <Flex gap={"small"}>
                        <Typography.Text><b>{getScoreStatus(selectedPetInfo.points.status)}</b></Typography.Text>
                        <Popover title={t("organization.animals.drawer.score-details")} content={<ScoreDetails score={selectedPetInfo.points}/>}>
                            <InfoCircleOutlined style={{color: customTheme.colorPrimary}}/>
                        </Popover>
                    </Flex>
                </Flex>
            </Flex>
            <Divider />
            <Flex vertical>
                <Typography.Title level={4}>{t("organization.animals.health-status")} :</Typography.Title>
                {renderAnalysisMessage(selectedPetInfo.healthStatus)}
                <Typography.Title level={5}>{t("organization.animals.drawer.last-behaviors")} :</Typography.Title>
                <Flex>
                    {selectedPetInfo.lastBehaviors.length > 0 ?
                        <OrganizationBehaviorsDisplay behaviors={selectedPetInfo.lastBehaviors}/>
                        :
                        <Typography.Text>{t("organization.animals.drawer.no-behaviors")}</Typography.Text>
                    }
                </Flex>
            </Flex>
            <Divider />
            <Flex align="end" gap={"small"}>
                <Typography.Title level={4} style={{margin: 0}}>{t("organization.animals.drawer.activity")} :</Typography.Title>
                <Typography.Text>{t("organization.animals.drawer.this-week")}, <b>{selectedPetInfo.activityStatus.numberOfActivities}</b> {t("organization.animals.drawer.activities-registered")} : <b>{Math.floor(selectedPetInfo.activityStatus.time / 3600)}h {Math.round(selectedPetInfo.activityStatus.time % 3600 / 60)}m</b></Typography.Text>
            </Flex>
            <Divider/>
            <Flex vertical>
                <Typography.Title level={4}>{t("weight-section.weight")} :</Typography.Title>
                {selectedPetInfo.weights.length > 0 ? (
                    <Flex vertical>
                        <Typography.Text>{t("organization.animals.drawer.last-weight")} : <b>{selectedPetInfo.weights[selectedPetInfo.weights.length - 1].weight} kg</b></Typography.Text>
                        <Typography.Text style={{ fontSize: '11px', color: 'rgba(116, 113, 114, 0.8)' }}>
                            {selectedPetInfo.weights[selectedPetInfo.weights.length - 1].created_at}
                        </Typography.Text>
                    </Flex>
                ) : (
                    <Typography.Text><b>{t("weight-section.no-weight")}</b></Typography.Text>
                )}
                <Flex style={{height: '200px'}}>
                    <ResponsiveContainer width="100%" height='100%'>
                        <LineChart
                            width={550}
                            height={200}
                            data={selectedPetInfo.weights || []}
                            className='weight-graph'
                            style={{ maxWidth: '600px', minWidth: '100px' }}
                        >
                            {showRefArea &&
                                <ReferenceArea
                                    y1={selectedPetInfo.weightRange[0]} y2={selectedPetInfo.weightRange[1]}
                                    fill={customTheme.colorPrimary} fillOpacity={0.2}
                                    ifOverflow="extendDomain"
                                />
                            }
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="created_at" hide/>
                            <Tooltip content={<CustomTooltipWeight/>}/>
                            <Line type="monotone" dataKey="weight" stroke={customTheme.colorPrimary} strokeWidth={2}/>
                        </LineChart>
                    </ResponsiveContainer>
                </Flex>
            </Flex>
            <Divider/>
            <Flex vertical>
                <Typography.Title level={4}>{t("organization.dashboard.vet-appointments")} :</Typography.Title>
                <Flex justify="space-between">
                    <Flex vertical>
                        <Typography.Text>{t("treatment-reminder-creation.treatment-reminder-type.vaccine")} : <b>{getTreatmentStatus(selectedPetInfo?.treatmentsStatus?.vaccineStatus)}</b></Typography.Text>
                        <Typography.Text>{t("treatment-reminder-creation.treatment-reminder-type.deworming")} : <b>{getTreatmentStatus(selectedPetInfo?.treatmentsStatus?.dewormingStatus)}</b></Typography.Text>
                        <Typography.Text>{t("treatment-reminder-creation.treatment-reminder-type.anti-parasitic")} : <b>{getTreatmentStatus(selectedPetInfo?.treatmentsStatus?.antiparasiticStatus)}</b></Typography.Text>
                    </Flex>
                    <Flex vertical style={{textAlign: 'right'}}>
                        <Typography.Text><b>{selectedPetInfo.vetAppointments.list.length === 0 ? t("organization.animals.drawer.none") : `${selectedPetInfo.vetAppointments.list.length}`}</b> {t("organization.animals.drawer.last-year-appointment")}</Typography.Text>
                        {selectedPetInfo.vetAppointments.lastDate &&
                            <Typography.Text>{t("organization.animals.drawer.last-appointment")} <b>{selectedPetInfo.vetAppointments.lastDate}</b></Typography.Text>
                        }
                    </Flex>
                </Flex>
            </Flex>
        </Drawer>
    )
}

export default PetOrganizationDrawer;