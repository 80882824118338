import { Flex, Select, Typography } from "antd";
import { useTranslation } from "react-i18next";

function OrganizationSelect(props: any) {
    const organizations = props.organizations;
    const {t} = useTranslation();

    function handleChange(value: any) {
        const selectedOrganization = organizations.find((org: any) => org._id === value);
        props.onOrganizationChange(selectedOrganization);
    }

    return (
        <Flex gap={"small"} align="center">
            <Typography.Title level={3} style={{margin: "0"}}>{t("organization.title")} : </Typography.Title>
            <Select
                style={{ width: "200px", height: "40px" }}
                placeholder={t("organization.select")}
                options={organizations.map((org: any) => ({
                    label: org.title,
                    value: org._id,
                }))}
                value={props.organization?._id}
                onChange={handleChange}
            />
        </Flex>
    )
}

export default OrganizationSelect;