import { Button,  Divider, Flex, Input,  List, Popconfirm, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';

import './profile.css'
import AddVets from './AddVets';
import InfoContainer from '../infoContainer/InfoContainer';
import { deleteVet, getVetFromUser, updateVet } from '../../request/vet';
import { GoTrash } from 'react-icons/go';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

type Vet = {
    vet_name: string;
    vet_clinic: string;
    vet_email: string;
    vet_phone_number: string;
    _id: string
}

function VetsDisplay(props: any) {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const {t} = useTranslation();

    const [openModifyVet, setOpenModifyVet] = useState<boolean[]>([])
    const [vets, setVets] = useState<Vet[]>();

    const [vetClinic, setVetClinic] = useState<string>("");
    const [vetName, setVetName] = useState<string>("");
    const [vetEmail, setVetEmail] = useState<string>("");
    const [vetPhoneNumber, setVetPhoneNumber] = useState<string>("");

    const getAccessToken = async () => {
        if (isAuthenticated) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
              scope: "openid profile email",
            },
          });
          return accessToken;
        }
        return "";
    }

    const handleModifyOpen = (key: number, vet: Vet) => {
        setOpenModifyVet(prevState => {
            const newState = Object.keys(prevState).reduce((acc: any, index: any) => {
                acc[index] = false;
                return acc;
            }, {});
            newState[key] = !openModifyVet[key];
            return newState;
        });
        setVetClinic(vet.vet_clinic);
        setVetName(vet.vet_name);
        setVetEmail(vet.vet_email);
        setVetPhoneNumber(vet.vet_phone_number);
    }

    const handleSubmit = async (id: string) => {
        try {
            await updateVet(await getAccessToken(), vetName, vetClinic, vetEmail, vetPhoneNumber, id)
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
        getVets();
    }

    const handleDeleteVet = async (id: string) => {
        try {
            await deleteVet(await getAccessToken(), id)
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
        setOpenModifyVet(prevState => {
            const newState = Object.keys(prevState).reduce((acc: any, index: any) => {
                acc[index] = false;
                return acc;
            }, {});
            return newState;
        });
        getVets();
    }

    const getVets = async () => {
        setVets(await getVetFromUser(await getAccessToken()));
    }

    useEffect(() => {
        getVets();
    }, []);

    return (
        <div style={{maxWidth: '800px', margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Divider className='profil-divider'>{vets && vets?.length > 1 ? t("vet-profile.title") : t("vet-profile.title").slice(0, -1)}</Divider>
            {vets && vets.map((vet: Vet, key: number) => (
                <InfoContainer key={key}>
                    <Flex vertical>
                        <List
                            bordered
                            style={{ width: '100%', padding: '10px', border: 'none' }}
                            size="large"
                            dataSource={
                                openModifyVet[key] ? [
                                    <><Typography.Text>{t("vet-profile.name")}</Typography.Text><Input value={vetName} onChange={(e) => setVetName(e.target.value)}/></>,
                                    <><Typography.Text>{t("vet-profile.clinic")}</Typography.Text><Input title={t("vet-profile.clinic")} value={vetClinic} onChange={(e) => setVetClinic(e.target.value)}/></>,
                                    <><Typography.Text>{t("vet-profile.email")}</Typography.Text><Input title={t("vet-profile.email")} value={vetEmail} onChange={(e) => setVetEmail(e.target.value)}/></>,
                                    <><Typography.Text>{t("vet-profile.phone-number")}</Typography.Text><Input title={t("vet-profile.phone-number")} value={vetPhoneNumber} onChange={(e) => setVetPhoneNumber(e.target.value)}/></>,
                                ] : [
                                    <span>{t("vet-profile.name")}: <b>{vet.vet_name}</b></span>,
                                    <span>{t("vet-profile.clinic")}: <b>{vet.vet_clinic}</b></span>,
                                    <span>{t("vet-profile.email")}: <b>{vet.vet_email}</b></span>,
                                    <span>{t("vet-profile.phone-number")}: <b>{vet.vet_phone_number}</b></span>,
                                ]
                            }
                            renderItem={(item) => <List.Item style={{ padding: '8px' }}>{item}</List.Item>}
                        />
                        <Flex
                            justify={openModifyVet[key] ? "space-between" : "flex-end"}
                            style={{ marginTop: '5px', backgroundColor: 'rgba(214, 214, 214, 0.5)', borderRadius: '5px', alignItems: 'center', padding: '10px' }}
                        >
                            {openModifyVet[key] &&
                                <Popconfirm
                                    title={t("vet-profile.delete")}
                                    description={t("vet-profile.delete-confirm")}
                                    onConfirm={() => handleDeleteVet(vet._id)}
                                    okText={t("form.remove")}
                                    cancelText={t("form.cancel")}
                                >
                                    <Button
                                        danger
                                        icon={<GoTrash />}
                                    >
                                        {t("form.remove")}
                                    </Button>
                                </Popconfirm>
                            }
                            <Flex gap={5}>
                                {openModifyVet[key] ?
                                    <>
                                        <Button
                                            type='primary'
                                            disabled={(vetName === "") && (vetClinic == "")}
                                            onClick={() => {
                                                handleModifyOpen(key, vet);
                                                handleSubmit(vet._id)
                                            }}
                                        >
                                            {t("form.save")}
                                        </Button>
                                        <Button
                                            style={{ overflow: 'hidden' }}
                                            onClick={() => handleModifyOpen(key, vet)}
                                            danger
                                        >
                                            {t("form.cancel")}
                                        </Button>
                                    </>
                                    :
                                    <Button
                                        style={{ overflow: 'hidden' }}
                                        onClick={() => handleModifyOpen(key, vet)}
                                        icon={<EditOutlined />}
                                    />
                                }
                            </Flex>
                        </Flex>
                    </Flex>
                </InfoContainer>
            ))}
            <Flex style={{ marginBottom: '20px', marginTop: '20px' }}>
                <AddVets renew={getVets}/>
            </Flex>
        </div>
    );
}

export default VetsDisplay;
