import axios from "axios";

async function getOrganizations(accessToken: string): Promise<any> {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/organization`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                withCredentials: true
            },
        );
        return response.data;
    } catch (error: any) {
        return null;
    }
}

async function getOrganizationInfo(accessToken: string, id: string): Promise<any> {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/organization/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                withCredentials: true
            },
        );
        return response.data;
    } catch (error: any) {
        return null;
    }
}

async function getOrganizationPetInfo(accessToken: string, organizationId: string, petId: string) {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/organization/${organizationId}/pet/${petId}`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                withCredentials: true
            },
        );
        return response.data;
    } catch (error: any) {
        return null;
    }
}

async function createOrganization(accessToken: string, title: string): Promise<any> {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/organization`,
            {
                title
            },
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                withCredentials: true
            },
        );
        return response.data;
    } catch (error: any) {
        throw error.response.status;
    }
}

async function deleteOrganization(accessToken: string, id: string): Promise<any> {
    try {
        const response = await axios.delete(
            `${process.env.REACT_APP_API_URL}/organization/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                withCredentials: true
            },
        );
        return response.data;
    } catch (error: any) {
        throw error.response.status;
    }
}

async function updateOrganization(accessToken: string, id: string, title: string): Promise<any> {
    try {
        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/organization/${id}`,
            {
                title
            },
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                withCredentials: true
            },
        );
        return response.data;
    } catch (error: any) {
        throw error.response.status;
    }
}

async function addMemberInOrganization(accessToken: string, id: string, email: string): Promise<any> {
    try {
        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/organization/addMember/${id}`,
            {
                userEmail: email
            },
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                withCredentials: true
            },
        );
        return response.data;
    } catch (error: any) {
        throw error.response.status;
    }
}

async function addPetOwnerInOrganization(accessToken: string, id: string, email: string): Promise<any> {
    try {
        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/organization/addPetOwner/${id}`,
            {
                userEmail: email
            },
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                withCredentials: true
            },
        );
        return response.data;
    } catch (error: any) {
        throw error.response.status;
    }
}

async function removeMemberInOrganization(accessToken: string, id: string, email: string): Promise<any> {
    try {
        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/organization/removeMember/${id}`,
            {
                userEmail: email
            },
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                withCredentials: true
            },
        );
        return response.data;
    } catch (error: any) {
        throw error.response.status;
    }
}

async function removePetOwnerInOrganization(accessToken: string, id: string, email: string): Promise<any> {
    try {
        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/organization/removePetOwner/${id}`,
            {
                userEmail: email
            },
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                withCredentials: true
            },
        );
        return response.data;
    } catch (error: any) {
        throw error.response.status;
    }
}

export {
    getOrganizations,
    getOrganizationInfo,
    getOrganizationPetInfo,
    createOrganization,
    deleteOrganization,
    updateOrganization,
    addMemberInOrganization,
    addPetOwnerInOrganization,
    removeMemberInOrganization,
    removePetOwnerInOrganization
};