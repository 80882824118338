import React, { useEffect, useRef, useState } from 'react';
import { Button, Divider, Flex, Select, Typography } from 'antd';
import dayjs from 'dayjs';
import { PlayCircleOutlined, RightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import './activity.css'
import InfoContainer from '../infoContainer/InfoContainer';
import { createActivity, getTodayActiviyFromPet, updateTodayActivity } from '../../request/activity';
import { ActivityTimePicker, ActivityChronoPicker } from './ActivityPicker';
import { useGlobalState } from '../../state/globalState';
import { ActivityDrawer } from './drawer/ActivityDrawer';
import { customTheme } from '../../config/theme';
import { dateFormat } from '../../config/format';
import { useAuth0 } from '@auth0/auth0-react';
import { useAnimationContext } from '../../contexts/Animation';

interface Activity {
    activity: {created_at: string, duration: string, id: number, type: string}[],
    created_at: string
}

export function secondsToHms(seconds: number) {
    seconds = Number(seconds);
    var h = Math.floor(seconds / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 3600 % 60);

    return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`;
}

function Activity() {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const {t} = useTranslation();

    const [reRender, setReRender] = useGlobalState('reRender');
    const [selectedPetName, setSelectedPetName] = useGlobalState('selectedPetName');
    const [reRenderUserScore, setReRenderUserScore] = useGlobalState('reRenderUserScore');

    const {setStartAnimation, setAnimationCoord} = useAnimationContext()

    const [petActivity, setPetActivity] = useState<Activity>({activity: [], created_at: dayjs().format(`${dateFormat}`)});
    const [activity, setActivity] = useState<string>("00:00:00");

    const [hours, setHours] = useState<string>('00');
    const [minutes, setMinutes] = useState<string>('00');
    const [seconds, setSeconds] = useState<string>('00');
    const [activityType, setActivityType] = useState<string>("");

    const [isChronoRunning, setIsChronoRunning] = useState<boolean>(false)
    const [openStateDrawer, setOpenStateDrawer] = useState<boolean>(false);

    const [alreadyCreated, setAlreadyCreated] = useState(false);

    const getAccessToken = async () => {
        if (isAuthenticated) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
              scope: "openid profile email",
            },
          });
          return accessToken;
        }
        return "";
    }

    const handleSubmit = async () => {
        const newPetActivity = petActivity;
        const duration = `${hours}:${minutes}:${seconds}`
        let result: {score: boolean} = {score: false};

        newPetActivity.activity.push({
            created_at: dayjs().format(`${dateFormat} HH:mm`),
            duration,
            id: (newPetActivity.activity.length >= 1 ? newPetActivity.activity[newPetActivity.activity.length - 1]?.id : 0) + 1,
            type: activityType})
        await setPetActivity(newPetActivity);

        if (!alreadyCreated) {
            result = await createActivity(await getAccessToken(), petActivity.activity, dayjs().format(`${dateFormat}`));
            setAlreadyCreated(true);
        } else {
            result = await updateTodayActivity(await getAccessToken(), petActivity.activity, dayjs().format(`${dateFormat}`));
        }
        if (result.score) {
            setReRenderUserScore(new Date());
            setStartAnimation(true)
        }
        addTodayActivity(petActivity.activity)
    }

    const addTodayActivity = async (activity: {created_at: string, duration: string, id: number}[]) => {
        let seconds = 0;

        for (let i = 0; activity && i < activity.length; i++) {
            const [h, m, s] = activity[i].duration.split(':');
            seconds += (Number(h) * 60 * 60 + Number(m) * 60 + Number(s));
        }
        setActivity(await secondsToHms(seconds));
    }

    const getTodayPetActivity = async () => {
        const result = await getTodayActiviyFromPet(await getAccessToken());

        setAlreadyCreated(!(result == null));
        if (result) {
            setPetActivity(result);
            addTodayActivity(result.activity)
        } else {
            setPetActivity({activity: [], created_at: dayjs().format(`${dateFormat}`)});
            setActivity("00:00:00");
        }
    }

    const onDrawerClose = (reRender: boolean) => {
        setTimeout(() => {
          setOpenStateDrawer(false)
        }, 250);
        if (reRender) {
            getTodayPetActivity();
        }
    }

    useEffect(() => {
        getTodayPetActivity()
    }, [reRender]);

  return (
      <div>
        <InfoContainer
            cardTitle={`${t("activity-section.activity-of")} ${selectedPetName} - ${new Date().toLocaleDateString('fr-FR', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                }).replaceAll('/', '-')}`
            }
            extra={
            <Flex
                justify='center'
                style={{ height: '30px', width: '30px', cursor: 'pointer' }}
                onClick={() => {
                  setOpenStateDrawer(true)
                }}
              >
                <RightOutlined/>
              </Flex>
            }
        >
            <Typography.Title level={2} style={{ textAlign: 'center' }}>
                {activity}
            </Typography.Title>
            <Divider style={{ margin: 0 }}/>
            <Flex justify='center' style={{ flexDirection: 'row', alignItems: 'center', height: '220px' }} gap={80}>
                {!isChronoRunning
                    ?   <>
                            <ActivityTimePicker
                                onSubmit={handleSubmit}
                                setHours={setHours}
                                setMinutes={setMinutes}
                                setSeconds={setSeconds}
                                setActivityType={setActivityType}
                            />
                            <Flex justify='center' style={{ flexDirection: 'column', alignItems: 'center', width: '130px' }} gap={8}>
                                <Button
                                    className='activity-btn-launch'
                                    type='primary' onClick={() => setIsChronoRunning(true)}
                                    style={{
                                        fontWeight: 'bold', borderRadius: '25px', width: '50px', height: '50px',
                                        backgroundColor: customTheme.colorPrimary
                                    }}
                                >
                                    <PlayCircleOutlined style={{ color: 'white', fontSize: '30px' }}/>
                                </Button>
                                <Typography.Text style={{ fontWeight: 'bold', textAlign: 'center' }}>{t("activity-section.launch-activity")}</Typography.Text>
                            </Flex>
                        </>
                    :   <ActivityChronoPicker
                            onSubmit={handleSubmit}
                            isChronoRunning={isChronoRunning}
                            setIsChronoRunning={setIsChronoRunning}
                            setHours={setHours}
                            setMinutes={setMinutes}
                            setSeconds={setSeconds}
                            setActivityType={setActivityType}
                        />
                }
            </Flex>
        </InfoContainer>
        {openStateDrawer &&
          <ActivityDrawer onClose={onDrawerClose} renew={getTodayPetActivity}/>
        }
      </div>
  );
}

export default Activity;
